import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Typography} from '@mui/material';

export const SignupSuccess = () =>{
  const navigate = useNavigate();
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const [isSuccess, setIsSuccess] = useState(query.get('account_confirmation_success'));

  useEffect(() =>{
    if(isSuccess == 'false'){
      navigate('/login');
    }
  }, [isSuccess]);

  const onClickCancel = () =>{
    navigate('/login');
  }

  const onClickReSend = () =>{
    navigate('/signup/confirmation');
  }

  return (
    <Box
      sx={{
        width: {md: '60%'},
        height: {md: '200px'},
        margin: '0 auto',
        minWidth: {md: '470px'},
        mt: {md: 10, xs: 2},
        pr: 2,
        pl: 2,
        pt: 1,
        pb: 1
      }}
    >
      {isSuccess == 'true' ? 
        <>
        <Typography>新規アカウント作成が完了しました。</Typography>
        <Typography>早速ログインしてユーザー情報を登録してください。</Typography>
        </>
      :
        <>
        <Typography sx={{fontWeight: 'bold', fontSize: 17, pt: 5}}>有効期限が切れています。</Typography>
        <Typography>再度会員登録を行なってください。</Typography>
        </>
      }
      <Box sx={{mt: 3}}>
        <Button
          color='secondary'
          variant='contained'
          onClick={() => onClickCancel()}
          sx={{borderRadius: 5, minWidth: '210px', width: {md: '25%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}}}
        >
          ログイン
        </Button>
        { isSuccess == 'true' ?
        <></>
        :
        <Button
          variant='contained'
          onClick={() => onClickReSend()}
          sx={{borderRadius: 5, minWidth: '210px', width: {md: '25%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}, ml: 1}}
        >
          再送する
        </Button>
        }
      </Box>
    </Box>
  )
}