import React, {useState, useEffect} from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Button, Box, Typography} from '@mui/material';
import { DeleteInput, deleteDefaultValues } from '../../../common/profile';
import Loading from "../../../components/Loading";
import { ErrorModal } from '../../../components/ErrorModal';

export const AdminDeleteConfirm = () =>{
  const location = useLocation();
  const [userInfo] = useState<DeleteInput>(location.state);
  const { control, handleSubmit, setValue} = useForm<DeleteInput>({defaultValues: deleteDefaultValues});
  const navigate = useNavigate();
  const {id} = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();

  useEffect(() => {
    if(id != null){
      setValue('reasonforwithdrawal', userInfo.reasonforwithdrawal);
      setValue('reasonforwithdrawalOther', userInfo.reasonforwithdrawalOther);
      setValue('hasoptedoutofemail', userInfo.hasoptedoutofemail);
      setValue('id', id);
    }else{
      // 別画面に遷移させる。
      // ページが存在しません。
      // 再度やり直してください。など
    }
  }, []);

  const onClickCancel = () =>{
    if(id == null) return;
    navigate("/admin/cancellation_of_membership/" + id);
  }

  const onSubmit: SubmitHandler<DeleteInput> = async (evt) =>{
    return;
  }

  return (
    <>
      <form id="user-confirm-form" onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            width: {md: '60%'},
            height: {md: '350px'},
            margin: '0 auto',
            borderRadius: '5px',
            minWidth: {md: '570px'},
            mt: {md: 10, xs: 2},
            textAlign: 'left',
            pr: 2,
            pl: 2,
            pt: 1,
            pb: 1
          }}
        >
          <Typography sx={{fontWeight: 'bold', fontSize: 17, pt: 5, textAlign: 'center', mb: 2}}>
            退会前に必ずご確認ください。
          </Typography>
          <Typography>・退会手続きを行うと、サービスがご利用できなくなります。</Typography>
          <Typography>・退会は取り消しができません。</Typography>
          <Typography>・削除した情報を再度復元できない可能性があります。</Typography>
          <Typography>・退会後、現在登録している同一アドレスでの再登録できない可能性があります。</Typography>
          <Typography sx={{mt: 2}}>上記ご確認の上、退会する場合は「退会する」ボタンをクリックしてください。</Typography>
          <Box sx={{mt: 3, textAlign: 'center'}}>
            <Button
              variant='contained'
              color='warning'
              onClick={() => onClickCancel()}
              sx={{borderRadius: 5, width: {md: '25%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}, mr: 1}}
              >
              キャンセル
            </Button>
          </Box>
        </Box>
      </form>
      {isLoading ? <Loading inverted={isLoading}/> : <></>}
      <ErrorModal message={errorMessage} modalOpen={errorModalOpen}></ErrorModal>
    </>
  )
}