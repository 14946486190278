import React from "react";
import { Box, Button, Typography } from '@mui/material';
import { useNavigate, useLocation } from "react-router-dom";
import FactoringFormTypes from "../../enums/factoringFormTypes";

export const FactoringMethod = () =>{
  const navigate = useNavigate();
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const draftId = query.get('draftId');

  const onClickCreate = () =>{
    navigate(`/apply/factoring_new?draftId=${draftId}&&clear=true`);
  }

  const onClickEdit = () =>{
    // ドラフト用
    navigate(`/factoring/app/draft?draftId=${draftId}`);
  }

  return (
      <Box
        sx={{
          width: {md: '60%'},
          height: {md: '200px'},
          margin: '0 auto',
          borderRadius: '5px',
          minWidth: {md: '570px'},
          mt: {md: 10, xs: 2},
          pr: 2,
          pl: 2,
          pt: 1,
          pb: 1
        }}
      >
        <Typography sx={{fontWeight: 'bold', fontSize: 17, pt: 5}}>
          下書きがあります。
        </Typography>
        <Typography>新規作成の場合、保存中の下書きは削除されるのでご注意ください。</Typography>
        <Box sx={{mt: 3}}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() =>{onClickEdit()}}
            sx={{borderRadius: 5, minWidth: '155px', width: {md: '20%', xs: '100%'}, fontWeight: 'bold', mb: {xs: 1, md: 2}, mr: 1}}
          >
            下書きから作成
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>{onClickCreate()}}
            sx={{borderRadius: 5, width: {md: '20%', xs: '100%'}, fontWeight: 'bold', mb: {xs: 1, md: 2}, mr: 1}}
          >
            新規作成
          </Button>
        </Box>
      </Box>
  );
}