import React, { useEffect, useState} from "react";
import { Box, Button, Card, CardActions, CardContent, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Link } from "react-router-dom";
import { getAccountsReceivables } from "../../lib/api/accounts_receivable"
import { Index, createIndexRow, columns, mobileColumns} from "../../common/accounts_receivable";
import dayjs from "dayjs";
import { getProfile } from "../../lib/api/profile";

export const SalesToIndex = () =>{
  const [listData, setListData] = useState<Index[]>([]);
  const [isAccountDisable, setIsAccountDisable] = useState(true);

  const handleGetAccountsReceivables = async () => {
    const res = await getAccountsReceivables();
    const res2 = await getProfile();

    if (res.status === 200) {
      setListData(res.data.records.map(rec =>{
        return createIndexRow(rec.id, rec.company || rec.name || '-', rec.state + rec.city + rec.street || '-', rec.closingdate || '-', rec.paymentdate || '-', rec.registrationdate || '-');
      }));
    }
    if(res2.status === 200){
      const record = res2.data.record;
      if(record.portaluserStatus == '利用停止') setIsAccountDisable(true);
      else setIsAccountDisable(false);
    }
  }

  useEffect(() => {
    handleGetAccountsReceivables()
  }, [])

  return (
    <Box sx={{ width: '100%' }}>
      {isAccountDisable ? <></> :
      <Button variant='contained' color='secondary' component={Link} to={`/ar_register`} sx={{borderRadius: 5, fontWeight: 'bold', mb: {xs: 2, md: 1}, width: {xs: '100%', md: "30%"}, minWidth: {md: '200px'}, maxWidth: {md: '200px'}}}>新規売掛先を追加</Button>
      }
      <Box sx={{ width: '100%', display: {xs: 'none', md: 'block'}}}>
      <TableContainer  sx={{ maxHeight: '70vh' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) =>(
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth}}
                  sx={{backgroundColor: "#FFF", p: 1, fontWeight: 'bold'}}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {listData
              .map((row) =>{
                return (
                  <TableRow hover tabIndex={-1} key={row.detailButton}>
                    {columns.map((column) =>{
                      const value = row[column.id];
                      return(
                        <TableCell key={column.id} align={column.align} sx={{p: 1}}>
                          {column.id == 'detailButton' 
                            ? <Button
                              variant='contained'
                              component={Link}
                              to={`/ar_info/${row.detailButton}`}
                              sx={{borderRadius: 5, fontWeight: 'bold'}}
                            >詳細を確認</Button>
                            : column.format && typeof value === 'number'
                            ? column.format(value)
                            : column.type && column.type == 'date'
                            ? dayjs(value).format('YYYY-MM-DD')
                            : value }
                        </TableCell>
                      )
                    })}
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
      </Box>
      <Box sx={{ width: '100%', display: {xs: 'block', md: 'none'}}}>
      <Grid
        container
        direction="column"
        alignContent="center"
        justifyContent="center"
        sx={{flexWrap: 'nowrap', WebkitFlexWrap: 'nowrap'}}
      >
        {listData
          .map((row) =>{
            const detailButton = row['detailButton'];
            const companyName = row['companyName'];
            const address = row['address'];
            const createdAt = row['createdAt'];
            const fixDate = row['fixDate'];
            const paymentDate = row['paymentDate'];
            return (
              <Grid item xs="auto" key={row.detailButton}>
                <Card
                  variant="outlined"
                  sx={{
                    mt: 1,
                    borderRadius: 3,
                    pb: 1,
                    mb: 2
                  }}
                >
                  <CardContent sx={{borderBottom: '1px solid #E7E6E0', pb: 1}}>
                    <Grid container sx={{mt: 1}}>
                      <Grid item xs={3} sx={{
                        m: 'auto',
                        textAlign: 'right',
                        pr: 1,
                      }}>
                        <Typography>
                          登録日
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sx={{
                        m: 'auto',
                        pl: 1
                      }}>
                        <Typography>
                          {createdAt}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container sx={{mt: 1}}>
                      <Grid item xs={12} sx={{
                        m: 'auto',
                        textAlign: 'left',
                        fontWeight: 'bold',
                        pl: 3
                      }}>
                        売掛先名
                      </Grid>
                      <Grid item xs={12} sx={{
                        m: 'auto',
                        textAlign: 'left',
                        pl: 5
                      }}>
                        <Typography>
                          {companyName}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container sx={{mt: 1}}>
                      <Grid item xs={12} sx={{
                        m: 'auto',
                        textAlign: 'left',
                        fontWeight: 'bold',
                        pl: 3
                      }}>
                        売掛先住所
                      </Grid>
                      <Grid item xs={12} sx={{
                        m: 'auto',
                        textAlign: 'left',
                        pl: 5
                      }}>
                        <Typography>{address}</Typography>
                      </Grid>
                    </Grid>
                    <Grid container sx={{mt: 1}}>
                      <Grid item xs={12} sx={{
                        m: 'auto',
                        textAlign: 'left',
                        fontWeight: 'bold',
                        pl: 3
                      }}>
                        締日
                      </Grid>
                      <Grid item xs={12} sx={{
                        m: 'auto',
                        textAlign: 'left',
                        pl: 5
                      }}>
                        <Typography>{fixDate}</Typography>
                      </Grid>
                    </Grid>
                    <Grid container sx={{mt: 1}}>
                      <Grid item xs={12} sx={{
                        m: 'auto',
                        textAlign: 'left',
                        fontWeight: 'bold',
                        pl: 3
                      }}>
                        支払日
                      </Grid>
                      <Grid item xs={12} sx={{
                        m: 'auto',
                        textAlign: 'left',
                        pl: 5
                      }}>
                        <Typography>{paymentDate}</Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant='contained' 
                      component={Link} 
                      to={`/ar_info/${detailButton}`}
                      sx={{borderRadius: 5, width: '100%', fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}}}
                    >
                      詳細を確認
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            );
          })
        }
      </Grid>
      </Box>
    </Box>
  );
};