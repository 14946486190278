import React, { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { getCurrentUser } from "../lib/api/auth";

type Props = {
  component: React.ReactNode;
  redirect: string
}

export const RouteAuthGuard: React.FC<Props> = (props) => {
  const signedIn = Cookies.get('isSignedIn');
  const navigate = useNavigate();
  const location = useLocation();

  const handleGetCurrentUser = async () =>{
    try{
      const ret = await getCurrentUser();
      console.log(ret);
      if(ret?.status == 200 && (ret.data?.message == 'ユーザーが存在しません。' || ret.data?.records?.length == 0)){
        Cookies.remove('currentUserId');
        Cookies.remove('isSignedIn');
        Cookies.remove('loginUserIds');
        Cookies.remove("_access_token");
        Cookies.remove("_client");
        Cookies.remove('_header_text');
        Cookies.remove("_uid");
        Cookies.remove("smsVerifiedAt");
        Cookies.remove('isSMSVerified');
        Cookies.remove('_users');
        Cookies.remove('_contact');
        navigate('/');
      }else{
        const data = ret?.data.data;
        const path = location.pathname;
        if(data?.isSmsVerified == false){
          // not sms
          if(path != '/signup/info' && path != '/signup/password' && path != '/faq' && path != '/terms_of_use' && path != '/login2' && path != '/signin' && path != '/signup/email'){
            navigate('/signup/info');
          }
        }else{
          if(path == '/signup/info' || path == '/signup/password'){
            navigate('/');
          }
        }
      }
    }catch(e){
      console.log(e);
      navigate('/')
    }
  };

  useEffect(() =>{
    handleGetCurrentUser();
  }, [location.pathname])
  
  return (
    signedIn ? <>{props.component}</> : <Navigate to={props.redirect} state={{from:useLocation()}} replace={false} />
  )
}