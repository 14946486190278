import dayjs, {Dayjs} from 'dayjs';
import { styled } from '@mui/material/styles';
import { Button, TextField } from '@mui/material';

export interface Input{
  id: string;
  kind: string;
  company: string;
  companynameFurigana: string;
  postalcode: string;
  state: string;
  city: string;
  street: string;
  businessPostalcode: string;
  businessState: string;
  businessCity: string;
  businessStreet: string;
  businessOther: string;
  corporatenumberAccount: string;
  industry: string;
  position: string;
  firstname: string;
  lastname: string;
  firstnamekana: string;
  lastnamekana: string;
  raPostalcode: string;
  raState: string;
  raCity: string;
  raStreet: string;
  raOther: string;
  email: string;
  mobilephone: string;
  seibetsu: string;
  birthdate: any;
  bankname: string;
  banknameSearch: string;
  branchname: string;
  accounttype: string;
  accountnumber: string;
  accountholder: string;
  accountnameKana: string;
  accounttypeOther: string;
  // emergencycontactName: string;
  // emergencycontactFurigana: string;
  // emergencycontactRelationship: string;
  // emergencycontactPhoneno: string;
  identification: string;
  code: string;
  bankcode: string;
  bankcodeBusinesstypecode: string;
  bankcodeBusinesstype: string;
  bankcodeHiragana: string;
  banknameFullwidthkana: string;
  banknameHalfwidthkana: string;
  branchcode: string;
  branchnameHiragana: string;
  branchnameFullwidthkana: string;
  branchnameHalfwidthkana: string;
  tashariyoureki: string;
  othercompaniesusedinthepast: string;
}

export interface FileInput {
  files: any;
  code: string;
  id: string;
}

export interface MemberInput {
  postalcode: string;
  state: string;
  city: string;
  street: string;
  raPostalcode: string;
  raState: string;
  raCity: string;
  raStreet: string;
  raOther: string;
  businessPostalcode: string;
  businessState: string;
  businessCity: string;
  businessStreet: string;
  businessOther: string;
  firstname: string;
  lastname: string;
  firstnamekana: string;
  lastnamekana: string;
  birthdate: any;
}

export interface DeleteInput {
  hasoptedoutofemail: boolean; 
  reasonforwithdrawal: string;
  reasonforwithdrawalOther: string;
  withdrawalstatus: string;
  id: string;
}

export const deleteDefaultValues: DeleteInput = {
  hasoptedoutofemail: true,
  reasonforwithdrawal: '',
  reasonforwithdrawalOther: '',
  withdrawalstatus: '退会済み',
  id: ''
}

export const memberDefaultValues: MemberInput = {
  postalcode: '',
  state: '',
  city: '',
  street: '',
  raPostalcode: '',
  raState: '',
  raCity: '',
  raStreet: '',
  raOther: '',
  businessPostalcode: '',
  businessState: '',
  businessCity: '',
  businessStreet: '',
  businessOther: '',
  firstname: '',
  lastname: '',
  firstnamekana: '',
  lastnamekana: '',
  birthdate: dayjs('1990-01-01')
}

export const defaultValues: Input ={
  id: '',
  kind: '',
  company: '',
  companynameFurigana: '',
  postalcode: '',
  state: '',
  city: '',
  street: '',
  businessPostalcode: '',
  businessState: '',
  businessCity: '',
  businessStreet: '',
  businessOther: '',
  corporatenumberAccount: '',
  industry: '',
  position: '',
  firstname: '',
  lastname: '',
  firstnamekana: '',
  lastnamekana: '',
  raPostalcode: '',
  raState: '',
  raCity: '',
  raStreet: '',
  raOther: '',
  email: '',
  mobilephone: '',
  seibetsu: '',
  birthdate: dayjs('1990-01-01'),
  bankname: '',
  banknameSearch: '',
  branchname: '',
  accounttype: '',
  accountnumber: '',
  accountholder: '',
  accountnameKana: '',
  accounttypeOther: '',
  // emergencycontactName: '',
  // emergencycontactFurigana: '',
  // emergencycontactRelationship: '',
  // emergencycontactPhoneno: '',
  identification: '',
  code: '',
  bankcode: '',
  bankcodeBusinesstypecode: '',
  bankcodeBusinesstype: '',
  bankcodeHiragana: '',
  banknameFullwidthkana: '',
  banknameHalfwidthkana: '',
  branchcode: '',
  branchnameHiragana: '',
  branchnameFullwidthkana: '',
  branchnameHalfwidthkana: '',
  tashariyoureki: '',
  othercompaniesusedinthepast: ''
}

export const defaultFileInput = {
  files: null,
  code: '',
  id: ''
}

export interface Corporate{
  name: string;
  furigana: string;
  postCode: string;
  prefectureName: string;
  cityName: string;
  streetNumber: string;
  corporateNumber: string;
}

interface SelectOption{
  label: string;
  value: any;
}
interface pField{
  // id: 'id' | 'kind' | 'company' | 'companynameFurigana' | 'postalcode' | 'state' | 'city' | 'street' | 'businessPostalcode' | 'businessState' | 'businessCity' | 'businessStreet' | 'businessOther' | 'corporatenumberAccount' | 'industry' | 'position' | 'firstname' | 'lastname' | 'firstnamekana' | 'lastnamekana' | 'raPostalcode' | 'raState' | 'raCity' | 'raStreet' | 'raOther' | 'email' | 'mobilephone' | 'seibetsu' | 'birthdate' | 'bankname' | 'banknameSearch' | 'branchname' | 'accounttype' | 'accountnumber' | 'accountholder' | 'accountnameKana' | 'accounttypeOther' | 'emergencycontactName' | 'emergencycontactFurigana' | 'emergencycontactRelationship' | 'emergencycontactPhoneno' | 'identification' | 'code' | 'bankcode' | 'bankcodeBusinesstypecode' | 'bankcodeBusinesstype' | 'bankcodeHiragana' | 'banknameFullwidthkana' | 'banknameHalfwidthkana' | 'branchcode' | 'branchnameHiragana' | 'branchnameFullwidthkana' | 'branchnameHalfwidthkana' | 'tashariyoureki' | 'othercompaniesusedinthepast';
  id: 'id' | 'kind' | 'company' | 'companynameFurigana' | 'postalcode' | 'state' | 'city' | 'street' | 'businessPostalcode' | 'businessState' | 'businessCity' | 'businessStreet' | 'businessOther' | 'corporatenumberAccount' | 'industry' | 'position' | 'firstname' | 'lastname' | 'firstnamekana' | 'lastnamekana' | 'raPostalcode' | 'raState' | 'raCity' | 'raStreet' | 'raOther' | 'email' | 'mobilephone' | 'seibetsu' | 'birthdate' | 'bankname' | 'banknameSearch' | 'branchname' | 'accounttype' | 'accountnumber' | 'accountholder' | 'accountnameKana' | 'accounttypeOther' | 'identification' | 'code' | 'bankcode' | 'bankcodeBusinesstypecode' | 'bankcodeBusinesstype' | 'bankcodeHiragana' | 'banknameFullwidthkana' | 'banknameHalfwidthkana' | 'branchcode' | 'branchnameHiragana' | 'branchnameFullwidthkana' | 'branchnameHalfwidthkana' | 'tashariyoureki' | 'othercompaniesusedinthepast';
  placeholder: string;
  type: string;
  html_id: string;
  options: SelectOption[];
  disable?: boolean;
  message?: string;
}
interface FormInfo {
  id: 'horizontal' | 'vertical' ;
  label: string;
  required: boolean;
  fields: pField[];
}

interface ProfileGroup {
  group_class: string;
  color: string;
  title: string;
  message: string;
  warning?: string;
  warnings?: string[];
  forms: FormInfo[];
}

interface Field{
  id: 'postalcode' | 'state' | 'city' | 'street' | 'raPostalcode' | 'raState' | 'raCity' | 'raStreet' | 'raOther' | 'firstname' | 'lastname' | 'firstnamekana' | 'lastnamekana' | 'birthdate' ;
  placeholder: string;
  type: string;
  html_id: string;
  options: SelectOption[];
  disable?: boolean;
}

interface memberFormInfos{
  id: 'horizontal' | 'vertical' ;
  label: string;
  required: boolean;
  fields: Field[];
}

interface deleteFormInfo{
  id: 'hasoptedoutofemail' | 'reasonforwithdrawal' | 'reasonforwithdrawalOther' | 'id';
  label: string;
  type: string;
  html_id: string;
  options: SelectOption[];
  required: boolean;
  disable?: boolean;
}

const industryArray = [
  "農業",
  "林業",
  "漁業",
  "鉱業",
  "建設業",
  "製造業",
  "電気・ガス・熱供給・水道業",
  "情報通信業",
  "運輸業",
  "卸売・小売業",
  "金融・保険業",
  "不動産業",
  "飲食店・宿泊業",
  "医療・福祉",
  "教育・学習支援業",
  "複合サービス事業（郵便局、協同組合）",
  "人材サービス業",
  "警備業",
  "サービス業"
]

const positionArray = [
  "代表取締役",
  "取締役",
  "経理",
  "親族",
  "その他"
]

const emergencyRelations = [
  "経理担当者",
  "親族",
  "その他"
]

const reasonforwithdrawals = [
  "調達希望日に間に合わない",
  "希望額を調達できない",
  "手数料が高い",
  "サイトが使いづらい",
  "サポート体制に不満がある",
  "会員向けの特典・サービスが少ない",
  "他社のファクタリングを利用する",
  "融資に通ったため",
  "資金繰りが改善したため",
  "その他(記述式)"
]

const accountTypes = [
  {label: '普通', value: '普通'},
  {label: '当座', value: '当座'},
  {label: 'その他', value: 'その他(記述式)'}
]

export const validationRules = {
  company:{
    maxLength: {
      value: 255,
      message: '255文字以下で入力してください'
    },
    validate: (value, formValues) =>{
      if(!value){
        if(formValues.kind == '法人'){
          return '会社名を入力してください'
        }else{
          return '屋号を入力してください'
        }
      }
    }
  },
  companynameFurigana:{
    maxLength: {
      value: 255,
      message: '255文字以下で入力してください'
    },
    validate: (value, formValues) =>{
      if(!value){
        if(formValues.kind == '法人'){
          return '会社名(フリガナ)を入力してください'
        }else{
          return '屋号(フリガナ)を入力してください'
        }
      }
      const textValidPattern = /^[ァ-ン－ー—‐ｰ−–．／（）　]*$/u;
      return textValidPattern.test(value) || '使用できる文字はカタカナ、記号(ー．／（）)、全角スペースです';
    }
  },
  position:{
    maxLength: {
      value: 128,
      message: '128文字以下で入力してください'
    },
    required: '役職を入力してください'
  },
  postalcode:{
    maxLength: {
      value: 8,
      message: '8文字以下で入力してください'
    },
    required: '郵便番号を入力してください'
  },
  state:{
    maxLength: {
      value: 80,
      message: '80文字以下で入力してください'
    },
    required: '都道府県を入力してください',
    validate: (value, formValues) =>{
      const textValidPattern = /^[ａ-ｚＡ-Ｚ０-９\p{sc=Han}\p{sc=Hiragana}\p{sc=Katakana}ー－＇＆．（）・～／＜＞＋，＂ⅰⅠⅱⅡⅲⅢⅳⅣⅴⅤⅵⅥⅶⅦⅷⅧⅸⅨⅹⅩⅺⅪⅻⅫ]*$/u;
      return textValidPattern.test(value) || '使用できる文字はひらがな、カタカナ、全角英数、記号(ー＇＆．（）・～／＜＞＋，＂ⅰⅠⅱⅡⅲⅢⅳⅣⅴⅤⅵⅥⅶⅦⅷⅧⅸⅨⅹⅩⅺⅪⅻⅫ)です';
    }
  },
  city:{
    maxLength: {
      value: 40,
      message: '40文字以下で入力してください'
    },
    required: '市区町村を入力してください',
    validate: (value, formValues) =>{
      const textValidPattern = /^[ａ-ｚＡ-Ｚ０-９\p{sc=Han}\p{sc=Hiragana}\p{sc=Katakana}ー－＇＆．（）・～／＜＞＋，＂ⅰⅠⅱⅡⅲⅢⅳⅣⅴⅤⅵⅥⅶⅦⅷⅧⅸⅨⅹⅩⅺⅪⅻⅫ]*$/u;
      return textValidPattern.test(value) || '使用できる文字はひらがな、カタカナ、全角英数、記号(ー＇＆．（）・～／＜＞＋，＂ⅰⅠⅱⅡⅲⅢⅳⅣⅴⅤⅵⅥⅶⅦⅷⅧⅸⅨⅹⅩⅺⅪⅻⅫ)です';
    }
  },
  street:{
    maxLength: {
      value: 255,
      message: '255文字以下で入力してください'
    },
    required: '町名・番地を入力してください',
    validate: (value, formValues) =>{
      const textValidPattern = /^[ａ-ｚＡ-Ｚ０-９\p{sc=Han}\p{sc=Hiragana}\p{sc=Katakana}ー－＇＆．（）・～／＜＞＋，＂ⅰⅠⅱⅡⅲⅢⅳⅣⅴⅤⅵⅥⅶⅦⅷⅧⅸⅨⅹⅩⅺⅪⅻⅫ]*$/u;
      return textValidPattern.test(value) || '使用できる文字はひらがな、カタカナ、全角英数、記号(ー＇＆．（）・～／＜＞＋，＂ⅰⅠⅱⅡⅲⅢⅳⅣⅴⅤⅵⅥⅶⅦⅷⅧⅸⅨⅹⅩⅺⅪⅻⅫ)です';
    }
  },
  businessPostalcode:{
    maxLength: {
      value: 8,
      message: '8文字以下で入力してください'
    },
    required: '郵便番号を入力してください'
  },
  businessState:{
    maxLength: {
      value: 6,
      message: '6文字以下で入力してください'
    },
    required: '都道府県を入力してください',
    validate: (value, formValues) =>{
      const textValidPattern = /^[ａ-ｚＡ-Ｚ０-９\p{sc=Han}\p{sc=Hiragana}\p{sc=Katakana}ー－＇＆．（）・～／＜＞＋，＂ⅰⅠⅱⅡⅲⅢⅳⅣⅴⅤⅵⅥⅶⅦⅷⅧⅸⅨⅹⅩⅺⅪⅻⅫ]*$/u;
      return textValidPattern.test(value) || '使用できる文字はひらがな、カタカナ、全角英数、記号(ー＇＆．（）・～／＜＞＋，＂ⅰⅠⅱⅡⅲⅢⅳⅣⅴⅤⅵⅥⅶⅦⅷⅧⅸⅨⅹⅩⅺⅪⅻⅫ)です';
    }
  },
  businessCity:{
    maxLength: {
      value: 20,
      message: '20文字以下で入力してください'
    },
    required: '市区町村を入力してください',
    validate: (value, formValues) =>{
      const textValidPattern = /^[ａ-ｚＡ-Ｚ０-９\p{sc=Han}\p{sc=Hiragana}\p{sc=Katakana}ー－＇＆．（）・～／＜＞＋，＂ⅰⅠⅱⅡⅲⅢⅳⅣⅴⅤⅵⅥⅶⅦⅷⅧⅸⅨⅹⅩⅺⅪⅻⅫ]*$/u;
      return textValidPattern.test(value) || '使用できる文字はひらがな、カタカナ、全角英数、記号(ー＇＆．（）・～／＜＞＋，＂ⅰⅠⅱⅡⅲⅢⅳⅣⅴⅤⅵⅥⅶⅦⅷⅧⅸⅨⅹⅩⅺⅪⅻⅫ)です';
    }
  },
  businessStreet:{
    maxLength: {
      value: 50,
      message: '50文字以下で入力してください'
    },
    required: '町名・番地を入力してください',
    validate: (value, formValues) =>{
      const textValidPattern = /^[ａ-ｚＡ-Ｚ０-９\p{sc=Han}\p{sc=Hiragana}\p{sc=Katakana}ー－＇＆．（）・～／＜＞＋，＂ⅰⅠⅱⅡⅲⅢⅳⅣⅴⅤⅵⅥⅶⅦⅷⅧⅸⅨⅹⅩⅺⅪⅻⅫ]*$/u;
      return textValidPattern.test(value) || '使用できる文字はひらがな、カタカナ、全角英数、記号(ー＇＆．（）・～／＜＞＋，＂ⅰⅠⅱⅡⅲⅢⅳⅣⅴⅤⅵⅥⅶⅦⅷⅧⅸⅨⅹⅩⅺⅪⅻⅫ)です';
    }
  },
  businessOther:{
    maxLength: {
      value: 50,
      message: '50文字以下で入力してください',
      validate: (value, formValues) =>{
        const textValidPattern = /^[ａ-ｚＡ-Ｚ０-９\p{sc=Han}\p{sc=Hiragana}\p{sc=Katakana}ー－＇＆．（）・～／＜＞＋，＂ⅰⅠⅱⅡⅲⅢⅳⅣⅴⅤⅵⅥⅶⅦⅷⅧⅸⅨⅹⅩⅺⅪⅻⅫ]*$/u;
        return textValidPattern.test(value) || '使用できる文字はひらがな、カタカナ、全角英数、記号(ー＇＆．（）・～／＜＞＋，＂ⅰⅠⅱⅡⅲⅢⅳⅣⅴⅤⅵⅥⅶⅦⅷⅧⅸⅨⅹⅩⅺⅪⅻⅫ)です';
      }
    }
  },
  lastname:{
    maxLength: {
      value: 80,
      message: '80文字以下で入力してください'
    },
    required: '姓を入力してください',
    validate: (value, formValues) =>{
      const textValidPattern = /^[ａ-ｚＡ-Ｚ０-９ァ-ン－ー—‐ｰ−–\p{sc=Han}\p{sc=Hiragana}]*$/u;
      return textValidPattern.test(value) || '使用できる文字はひらがな、カタカナ、全角英数です';
    }
  },
  firstname:{
    maxLength: {
      value: 40,
      message: '40文字以下で入力してください'
    },
    required: '名を入力してください',
    validate: (value, formValues) =>{
      const textValidPattern = /^[ａ-ｚＡ-Ｚ０-９ァ-ン－ー—‐ｰ−–\p{sc=Han}\p{sc=Hiragana}]*$/u;
      return textValidPattern.test(value) || '使用できる文字はひらがな、カタカナ、全角英数です';
    }
  },
  lastnamekana:{
    maxLength: {
      value: 20,
      message: '20文字以下で入力してください'
    },
    required: 'セイを入力してください',
    validate: (value, formValues) =>{
      const textValidPattern = /^[ァ-ン－ー—‐ｰ−–．／（）　]*$/u;
      return textValidPattern.test(value) || '使用できる文字はカタカナ、記号(ー．／（）)、全角スペースです';
    }
  },
  firstnamekana: {
    maxLength: {
      value: 20,
      message: '20文字以下で入力してください'
    },
    required: 'メイを入力してください',
    validate: (value, formValues) =>{
      const textValidPattern = /^[ァ-ン－ー—‐ｰ−–．／（）　]*$/u;
      return textValidPattern.test(value) || '使用できる文字はカタカナ、記号(ー．／（）)、全角スペースです';
    }
  },
  raState:{
    maxLength: {
      value: 6,
      message: '6文字以下で入力してください'
    },
    required: '都道府県を入力してください',
    validate: (value, formValues) =>{
      const textValidPattern = /^[ａ-ｚＡ-Ｚ０-９\p{sc=Han}\p{sc=Hiragana}\p{sc=Katakana}ー－＇＆．（）・～／＜＞＋，＂ⅰⅠⅱⅡⅲⅢⅳⅣⅴⅤⅵⅥⅶⅦⅷⅧⅸⅨⅹⅩⅺⅪⅻⅫ]*$/u;
      return textValidPattern.test(value) || '使用できる文字はひらがな、カタカナ、全角英数、記号(ー＇＆．（）・～／＜＞＋，＂ⅰⅠⅱⅡⅲⅢⅳⅣⅴⅤⅵⅥⅶⅦⅷⅧⅸⅨⅹⅩⅺⅪⅻⅫ)です';
    }
  },
  birthdate:{
    required: '生年月日を入力してください',
    validate: (value) =>{
      if(!value.isValid()){
        return '日付の形式が不正です'
      }
    }
  },
  raPostalcode:{
    maxLength: {
      value: 8,
      message: '8文字以下で入力してください'
    },
    required: '郵便番号を入力してください'
  },
  raCity:{
    maxLength: {
      value: 20,
      message: '20文字以下で入力してください'
    },
    required: '市区町村を入力してください',
    validate: (value, formValues) =>{
      const textValidPattern = /^[ａ-ｚＡ-Ｚ０-９\p{sc=Han}\p{sc=Hiragana}\p{sc=Katakana}ー－＇＆．（）・～／＜＞＋，＂ⅰⅠⅱⅡⅲⅢⅳⅣⅴⅤⅵⅥⅶⅦⅷⅧⅸⅨⅹⅩⅺⅪⅻⅫ]*$/u;
      return textValidPattern.test(value) || '使用できる文字はひらがな、カタカナ、全角英数、記号(ー＇＆．（）・～／＜＞＋，＂ⅰⅠⅱⅡⅲⅢⅳⅣⅴⅤⅵⅥⅶⅦⅷⅧⅸⅨⅹⅩⅺⅪⅻⅫ)です';
    }
  },
  raStreet:{
    maxLength: {
      value: 50,
      message: '50文字以下で入力してください'
    },
    required: '町名・番地を入力してください',
    validate: (value, formValues) =>{
      const textValidPattern = /^[ａ-ｚＡ-Ｚ０-９\p{sc=Han}\p{sc=Hiragana}\p{sc=Katakana}ー－＇＆．（）・～／＜＞＋，＂ⅰⅠⅱⅡⅲⅢⅳⅣⅴⅤⅵⅥⅶⅦⅷⅧⅸⅨⅹⅩⅺⅪⅻⅫ]*$/u;
      return textValidPattern.test(value) || '使用できる文字はひらがな、カタカナ、全角英数、記号(ー＇＆．（）・～／＜＞＋，＂ⅰⅠⅱⅡⅲⅢⅳⅣⅴⅤⅵⅥⅶⅦⅷⅧⅸⅨⅹⅩⅺⅪⅻⅫ)です';
    }
  },
  raOther:{
    maxLength: {
      value: 50,
      message: '50文字以下で入力してください',
      validate: (value, formValues) =>{
        const textValidPattern = /^[ａ-ｚＡ-Ｚ０-９\p{sc=Han}\p{sc=Hiragana}\p{sc=Katakana}ー－＇＆．（）・～／＜＞＋，＂ⅰⅠⅱⅡⅲⅢⅳⅣⅴⅤⅵⅥⅶⅦⅷⅧⅸⅨⅹⅩⅺⅪⅻⅫ]*$/u;
        return textValidPattern.test(value) || '使用できる文字はひらがな、カタカナ、全角英数、記号(ー＇＆．（）・～／＜＞＋，＂ⅰⅠⅱⅡⅲⅢⅳⅣⅴⅤⅵⅥⅶⅦⅷⅧⅸⅨⅹⅩⅺⅪⅻⅫ)です';
      }
    }
  },
  email:{
    required: 'メールアドレスを入力してください',
    maxLength: {
      value: 80,
      message: '80文字以下で入力してください'
    },
    validate: (value, formValues) =>{
      const EmailVaildPattern = /^[a-zA-Z0-9._%+-/!#$%&'*=?^_`{|}~]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,4}$/;
      return EmailVaildPattern.test(value) || 'メールアドレスの形式が合っていません';
    }
  },
  mobilephone:{
    required: '携帯番号を入力してください',
    maxLength: {
      value: 40,
      message: '40文字以下で入力してください'
    },
    validate: (value, formValues) =>{
      const mobilephonePattern = /^(070|080|090)-?\d{4}-?\d{4}$/;
      return mobilephonePattern.test(value) || '携帯番号の形式が合っていません';
    }
  },
  seibetsu:{
    maxLength: {
      value: 255,
      message: '255文字以下で入力してください'
    },
    required: '性別を入力してください'
  },
  banknameSearch:{
    maxLength: {
      value: 255,
      message: '255文字以下で入力してください'
    },
    required: '銀行名を入力してください',
    validate: (value, formValues) =>{
      if(formValues.bankname != value){
        return '銀行名は候補の銀行を選択してください'
      }
    }
  },
  branchname:{
    maxLength: {
      value: 30,
      message: '30文字以下で入力してください'
    },
    required: '支店名を入力してください'
  },
  accounttype:{
    maxLength: {
      value: 255,
      message: '255文字以下で入力してください'
    },
    required: '口座種別を入力してください'
  },
  accounttypeOther:{
    maxLength: {
      value: 30,
      message: '30文字以下で入力してください'
    },
    validate: (value, formValues)=>{
      if(!value){
        if(formValues.accounttype == 'その他(記述式)'){
          return '口座種別(その他)を入力してください'
        }
      }
    }
  },
  accountnumber:{
    maxLength: {
      value: 7,
      message: '7文字以下で入力してください'
    },
    required: '口座番号を入力してください'
  },
  accountholder:{
    maxLength: {
      value: 100,
      message: '100文字以下で入力してください'
    },
    required: '口座名義を入力してください'
  },
  accountnameKana:{
    maxLength: {
      value: 100,
      message: '100文字以下で入力してください'
    },
    required: '口座名義(カナ)を入力してください',
    validate: (value, formValues) =>{
      const textValidPattern = /^[0-9A-Za-zｦ-ﾟ-()｢｣/ .,¥]*$/u;
      return textValidPattern.test(value) || '使用できる文字は半角英数ｶﾀｶﾅ、記号(-()｢｣/.,¥)、半角スペースです';
    }
  },
  // emergencycontactName:{
  //   maxLength: {
  //     value: 30,
  //     message: '30文字以下で入力してください'
  //   },
  //   required: 'お名前を入力してください'
  // },
  // emergencycontactFurigana:{
  //   maxLength: {
  //     value: 30,
  //     message: '30文字以下で入力してください'
  //   },
  //   required: 'お名前(カナ)を入力してください',
  //   validate: (value, formValues) =>{
  //     const textValidPattern = /^[ァ-ンー．／（）　]*$/u;
  //     return textValidPattern.test(value) || '使用できる文字はカタカナ、記号(ー．／（）)、全角スペースです';
  //   }
  // },
  // emergencycontactRelationship:{
  //   maxLength: {
  //     value: 255,
  //     message: '255文字以下で入力してください'
  //   },
  //   required: '続柄を入力してください'
  // },
  // emergencycontactPhoneno:{
  //   maxLength: {
  //     value: 40,
  //     message: '40文字以下で入力してください'
  //   },
  //   required: '電話番号を入力してください',
  //   validate: (value, formValues) =>{
  //     const mobilephonePattern = /^\d{2,5}[-(]?\d{1,4}[-)]?\d{4}$/;
  //     if(mobilephonePattern.test(value)){
  //       const nums = value.replace(/[()-]/g, '');
  //       return nums.length > 9 && nums.length < 12 || '電話番号は10桁以上11桁以内で入力してください'
  //     }else{
  //       return '電話番号の形式が合っていません';
  //     }
  //   }
  // },
  othercompaniesusedinthepast:{
    maxLength: {
      value: 100,
      message: '100文字以下で入力してください'
    },
  }
}

export const deleteValidationRules = {
  reasonforwithdrawal:{
    required: '退会理由を入力してください'
  },
  reasonforwithdrawalOther:{
    validate: (value, formValues) =>{
      if(formValues.reasonforwithdrawal == 'その他(記述式)' && !value){
        return '退会理由(その他)を入力してください'
      }
    }
  },
}

export const profileFormInfo: ProfileGroup[] = [
  {
    group_class: 'company_info',
    color: '#000000',
    title: '自社の基本情報を入力',
    message: '',
    forms: [
      {id: 'horizontal', label: '事業形態', required: true, fields: [
        {id: 'kind', placeholder: '事業形態', type: 'select', options: [{label: '法人', value: '法人'}, {label: '個人事業主', value: '個人事業主'}], html_id: 'kind_id', disable: true},
      ]},
      {id: 'horizontal', label: '会社名', required: true, fields: [
        {id: 'company', placeholder: '入力してください', type: 'searchText', options: [], html_id: ''},
      ]},
      {id: 'horizontal', label: '会社名(フリガナ)', required: true, fields: [
        {id: 'companynameFurigana', placeholder: '入力してください', type: 'string', options: [], html_id: '', message: '※全角カナ入力'},
      ]},
      {id: 'vertical', label: '会社住所', required: true, fields: [
        {id: 'postalcode', placeholder: '郵便番号', type: 'string', options: [], html_id: '', message: '※全角入力'},
        {id: 'state', placeholder: '都道府県', type: 'string', options: [], html_id: ''},
        {id: 'city', placeholder: '市区町村', type: 'string', options: [], html_id: ''},
        {id: 'street', placeholder: '町名・番地', type: 'string', options: [], html_id: ''}
      ]},
      {id: 'vertical', label: '事業所住所', required: true, fields: [
        {id: 'businessPostalcode', placeholder: '郵便番号', type: 'string', options: [], html_id: '', message: '※全角でご入力ください'},
        {id: 'businessState', placeholder: '都道府県', type: 'string', options: [], html_id: ''},
        {id: 'businessCity', placeholder: '市区町村', type: 'string', options: [], html_id: ''},
        {id: 'businessStreet', placeholder: '町名・番地', type: 'string', options: [], html_id: ''},
        {id: 'businessOther', placeholder: '建物名・部屋番号など', type: 'string', options: [], html_id: ''},
      ]},
      {id: 'horizontal', label: '法人番号', required: true, fields: [
        {id: 'corporatenumberAccount', placeholder: '', type: 'string', options: [], html_id: '', disable: true}
      ]},
      {id: 'horizontal', label: '業種', required: false, fields: [
        {id: 'industry', placeholder: '入力してください', type: 'select', options: industryArray.map((name) =>{return{value: name, label: name}}), html_id: 'industry_id'},
      ]},
      {id: 'horizontal', label: '他社利用歴', required: false, fields: [
        {id: 'tashariyoureki', placeholder: '入力してください', type: 'select', options: [{label: 'あり', value: '有り'}, {label: 'なし', value: '無し'}], html_id: ''},
      ]},
      {id: 'horizontal', label: '利用したことのあるファクタリング会社名', required: false, fields: [
        {id: 'othercompaniesusedinthepast', placeholder: '入力してください', type: 'string', options: [], html_id: ''}
      ]},
    ]
  },
  {
    group_class: 'ceo_info',
    color: '#000000',
    title: '代表者様の基本情報を入力',
    message: '',
    forms: [
      {id: 'horizontal', label: '役職', required: true, fields: [
        {id: 'position', placeholder: '例）代表取締役、代表、代表理事', type: 'string', options: [], html_id: 'position_id'},
      ]},
      {id: 'horizontal', label: '代表者名', required: true, fields: [
        {id: 'lastname', placeholder: '姓', type: 'string', options: [], html_id: '', message: '※全角入力'},
        {id: 'firstname', placeholder: '名', type: 'string', options: [], html_id: ''},
      ]},
      {id: 'horizontal', label: '代表者名(フリガナ)', required: true, fields: [
        {id: 'lastnamekana', placeholder: 'セイ', type: 'string', options: [], html_id: '', message: '※全角カナ入力'},
        {id: 'firstnamekana', placeholder: 'メイ', type: 'string', options: [], html_id: ''},
      ]},
      {id: 'vertical', label: '代表者住所', required: true, fields: [
        {id: 'raPostalcode', placeholder: '郵便番号', type: 'string', options: [], html_id: '', message: '※全角入力'},
        {id: 'raState', placeholder: '都道府県', type: 'string', options: [], html_id: ''},
        {id: 'raCity', placeholder: '市区町村', type: 'string', options: [], html_id: ''},
        {id: 'raStreet', placeholder: '町名・番地', type: 'string', options: [], html_id: ''},
        {id: 'raOther', placeholder: '建物名・部屋番号など', type: 'string', options: [], html_id: ''},
      ]},
      {id: 'horizontal', label: 'メールアドレス', required: true, fields: [
        {id: 'email', placeholder: '入力してください', type: 'string', options: [], html_id: '', message: '※契約書を送付するメールアドレスを入力（原則変更不可）'}
      ]},
      {id: 'horizontal', label: '携帯番号', required: true, fields: [
        {id: 'mobilephone', placeholder: '入力してください', type: 'string', options: [], html_id: '', message: '※SMSを受信可能な番号を入力'},
      ]},
      {id: 'horizontal', label: '性別', required: true, fields: [
        {id: 'seibetsu', placeholder: '入力してください', type: 'select', options: [{label: '男性', value: '男性'}, {label: '女性', value: '女性'}, {label: '回答しない', value: '回答しない'}], html_id: 'sex_id'}
      ]},
      {id: 'horizontal', label: '生年月日', required: true, fields: [
        {id: 'birthdate', placeholder: '入力してください', type: 'calendar', options: [], html_id: ''}
      ]},
      {id: 'horizontal', label: '本人確認の状況', required: false, fields: [
        {id: 'identification', placeholder: '', type: 'string', options: [], html_id: '', disable: true},
      ]}
    ]
  },
  {
    group_class: 'account_info',
    color: '#000000',
    title: '振込口座情報を入力',
    message: '',
    warnings: ['※口座情報に誤りがあると振り込みができませんので、十分ご注意ください。', '金融機関や口座状況により、処理が翌営業日になることもあります。'],
    forms: [
      {id: 'horizontal', label: '銀行名', required: true, fields: [
        {id: 'banknameSearch', placeholder: '検索ボタンクリック後、銀行名がサジェストされます', type: 'searchText', options: [], html_id: ''},
      ]},
      {id: 'horizontal', label: '支店名', required: true, fields: [
        {id: 'branchname', placeholder: '入力してください', type: 'searchText', options: [], html_id: ''},
      ]},
      {id: 'horizontal', label: '口座種別', required: true, fields: [
        {id: 'accounttype', placeholder: '入力してください', type: 'select', options: accountTypes, html_id: ''},
      ]},
      {id: 'horizontal', label: '口座種別(その他)', required: false, fields: [
        {id: 'accounttypeOther', placeholder: '入力してください', type: 'string', options: [], html_id: '', message: '※口座種別が「その他」の場合は入力'},
      ]},
      {id: 'horizontal', label: '口座番号', required: true, fields: [
        {id: 'accountnumber', placeholder: '入力してください', type: 'string', options: [], html_id: ''},
      ]},
      {id: 'horizontal', label: '口座名義', required: true, fields: [
        {id: 'accountholder', placeholder: '入力してください', type: 'string', options: [], html_id: ''},
      ]},
      {id: 'horizontal', label: '口座名義(ｶﾅ)', required: true, fields: [
        {id: 'accountnameKana', placeholder: '入力してください', type: 'string', options: [], html_id: '', message: '※半角ｶﾅ入力'}
      ]}
    ]
  }
  // ,{
  //   group_class: 'emergency_info',
  //   color: '#000000',
  //   title: '緊急連絡先（経理担当者）の情報を入力',
  //   message: '',
  //   warnings: ['経理担当の方がいらっしゃらない場合は親族の方をご入力ください', '※緊急時のみ使用いたします'],
  //   forms: [
  //     {id: 'horizontal', label: '氏名', required: true, fields: [
  //       {id: 'emergencycontactName', placeholder: '入力してください', type: 'string', options: [], html_id: ''},
  //     ]},
  //     {id: 'horizontal', label: '氏名(フリガナ)', required: true, fields: [
  //       {id: 'emergencycontactFurigana', placeholder: '入力してください', type: 'string', options: [], html_id: '', message: '※全角カナ入力'},
  //     ]},
  //     {id: 'horizontal', label: '続柄', required: true, fields: [
  //       {id: 'emergencycontactRelationship', placeholder: '入力してください', type: 'string', options: [], html_id: ''},
  //     ]},
  //     {id: 'horizontal', label: '電話番号', required: true, fields: [
  //       {id: 'emergencycontactPhoneno', placeholder: '入力してください', type: 'string', options: [], html_id: ''},
  //     ]},
  //   ]
  // }
];

export const memberFormInfo: memberFormInfos[] = [
  {id: 'horizontal', label: '代表者名', required: true, fields: [
    {id: 'lastname', placeholder: '姓', type: 'string', options: [], html_id: ''},
    {id: 'firstname', placeholder: '名', type: 'string', options: [], html_id: ''},
  ]},
  {id: 'horizontal', label: '代表者名(フリガナ)', required: true, fields: [
    {id: 'lastnamekana', placeholder: 'セイ', type: 'string', options: [], html_id: ''},
    {id: 'firstnamekana', placeholder: 'メイ', type: 'string', options: [], html_id: ''},
  ]},
  {id: 'vertical', label: '代表者住所', required: true, fields: [
    {id: 'raPostalcode', placeholder: '郵便番号', type: 'string', options: [], html_id: ''},
    {id: 'raState', placeholder: '都道府県', type: 'string', options: [], html_id: ''},
    {id: 'raCity', placeholder: '市区町村', type: 'string', options: [], html_id: ''},
    {id: 'raStreet', placeholder: '町名・番地', type: 'string', options: [], html_id: ''},
    {id: 'raOther', placeholder: '建物名・部屋番号など', type: 'string', options: [], html_id: ''}
  ]},
  {id: 'vertical', label: '生年月日', required: true, fields: [
    {id: 'birthdate', placeholder: '入力してください', type: 'calendar', options: [], html_id: ''},
  ]}
];

export const deletesFormInfo: deleteFormInfo[] = [
  {id: 'id', label: '', type: 'hidden', options: [], html_id: '', required: false},
  {id: 'reasonforwithdrawal', label: '退会理由', type: 'select', options: reasonforwithdrawals.map((name) =>{return{value: name, label: name}}), html_id: '', required: true},
  {id: 'reasonforwithdrawalOther', label: '退会理由(その他)', type: 'string', options: [], html_id: '', required: false},
  {id: 'hasoptedoutofemail', label: 'メルマガ配信', type: 'select', options: [{value: false, label: 'メルマガ配信を希望する'}, {value: true, label: 'メルマガ配信を希望しない'}], html_id: '', required: true}
];


export const TextFieldWButton = styled(Button)(({theme}) =>({
  height: '56px',
  width: '80px',
  marginTop: '16.5px',
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  overflow: 'hidden',
  whiteSpace: 'nowrap'
}));

export const TextFieldWText = styled(TextField)(({theme}) =>({
  width: 'calc(100% - 80px)',
  "& fieldset": {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  }
}));