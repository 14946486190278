import React from 'react';
import { Box, Button, Typography} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { getAccountsReceivable } from "../../lib/api/accounts_receivable"

type SalesTo = {
  name: string,
  id: string
}

export const SalesToCreateSuccess = () =>{
  const [salesTo, setSalesTo] = useState<SalesTo[]>([]);
  const navigate = useNavigate();

  const handleGetAccounts = async (pid: string) => {
    const res = await getAccountsReceivable(pid);
    if (res.status === 200 && res.data.record != null) {
      setSalesTo([res.data.record]);
    }
  }
  const {id} = useParams();
  useEffect(() => {
    if(id){
      handleGetAccounts(id);
    }
  }, [])

  const onClickFactoring = () =>{
    navigate("/top/factoring");
  }

  const onclickNew = () =>{
    navigate("/ar_register");
  }

  return (
    <Box
      sx={{
        width: {md: '60%'},
        height: {md: '200px'},
        margin: '0 auto',
        minWidth: {md: '570px'},
        mt: {md: 10, xs: 2},
        pr: 2,
        pl: 2,
        pt: 1,
        pb: 1
      }}
    >
      <Typography sx={{fontWeight: 'bold', fontSize: 17, pt: 5}}>
        売掛先の情報を登録しました。
      </Typography>
      { salesTo.map((st) =>{
        return <Typography>{st.name}</Typography>
      })}
      <Box sx={{mt: 3}}>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>{onClickFactoring()}}
          sx={{borderRadius: 5, minWidth: '210px', width: {md: '30%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}}}
        >
          ファクタリング申請
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>{onclickNew()}}
          sx={{borderRadius: 5, width: {md: '30%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}}}
        >
          続けて登録する
        </Button>
      </Box>
    </Box>
  );
}