import React from 'react';
import {Box, Divider, Typography } from '@mui/material';

export const AdminTermsOfService = () =>{
  return (
    <Box sx={{textAlign: 'left', backgroundColor: "#FFFFFF", p: 2, borderRadius: 2}}>
      <Typography sx={{fontSize: 20}}>会員サービス利用規約</Typography>
      <Typography sx={{fontSize: 18}}>第1条　（総則・適用範囲）</Typography>
      <Box component='ol' sx={{listStyleType: 'decimal'}}>
        <Box component='li' sx={{textAlign: 'left'}}>
          この会員サービス利用規約（以下「本規約」といいます。）は、株式会社ビートレーディング（以下「当社」といいます。）が提供する会員サービス（以下「本サービス」といいます。）の利用に関する条件を定めるものであり、本サービスの利用に関し、当社及び本サービスの利用者（以下「利用者」といいます。）に対して適用されます。
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
          当社の運営するウェブサイト（以下「当社ウェブサイト」といいます。）上又は本サービス上に本サービスに関する諸規定が別途掲載されている場合、及び、利用者と当社の間で個別の契約が別途締結される場合、利用者はかかる諸規定又は個別の契約上の規定（以下「個別規定」といいます。）にも従って本サービスを利用しなければなりません。個別規定の内容が本規約の内容と矛盾又は抵触する場合には、個別規定の内容が優先して適用されるものとします。
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
          本規約は、会員サービスの利用に関する事項を定めるものであり、当社が別途提供するファクタリングサービス及びこれに基づく利用者と当社との間の債権の買取りに関する事項を定めるものではありません。従って、本規約上の各規定は、当社の買取りにかかる債権の全部又は一部が債務者の債務不履行又は債務者に関する倒産手続等の開始により不払いとなった場合において、かかる債権の不払いに関し、利用者に対し何らの責任も負わせるものではなく、また、本規約上のいかなる規定も、利用者にかかる不払いの責任を負わせる結果となるように解釈・運用されてはならないものとします。
        </Box>
      </Box>
      <Typography sx={{fontSize: 18}}>第2条　（本規約への同意）</Typography>
      <Box component='ol' sx={{listStyleType: 'none'}}>
        <Box component='li' sx={{textAlign: 'left'}}>
          利用者は、本規約に従って本サービスを利用するものとし、本規約に同意しない限り本サービスを利用できないものとします。
        </Box>
      </Box>
      <Typography sx={{fontSize: 18}}>第3条　（本規約の変更）</Typography>
      <Box component='ol' sx={{listStyleType: 'decimal'}}>
        <Box component='li' sx={{textAlign: 'left'}}>
          当社は、利用者の事前の承諾を得ることなく、本規約を変更することができるものとします。
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
          当社は、本規約を変更するにあたり、本規約の内容を変更する旨、変更後の本規約の内容及びその効力発生日（以下「効力発生日」といいます。）を、効力発生日の1週間前までに、<Box component='a' sx={{wordBreak: 'break-all'}} className='link' target='_blank' href={`${location.origin}/admin/home`}>当社ウェブサイト</Box>への掲示又は利用者への個別の通知により、事前に利用者に周知するものとします。
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
          効力発生日以降の本サービスの利用条件等は変更後の本規約の内容によるものとし、利用者は、変更後の本規約の内容に同意しない場合には、直ちに本サービスの利用を終了するものとします。
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
          利用者が効力発生日以降も本サービスの利用を継続する場合、当該利用者は、変更後の本規約の内容に同意したものとみなされます。利用者は、自己の責任において、随時、本規約の最新の内容を確認の上、本サービスを利用するものとします。
        </Box>
      </Box>
      <Typography sx={{fontSize: 18}}>第4条　（会員登録）</Typography>
      <Box component='ol' sx={{listStyleType: 'decimal'}}>
        <Box component='li' sx={{textAlign: 'left'}}>
          利用者は、本条に従って会員登録を完了した場合に限り、本サービスを利用することができるものとします。
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
          本サービスの利用を希望する者（以下「申込者」といいます。）は、本規約に同意の上、当社の別途定める手続に従い、当社が指定する情報及び資料を当社に提供することにより、会員登録を申請するものとします。
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
          当社は、その裁量により、申込者による会員登録の申請を不承認とすることができるものとします。この場合、申込者は、当社に対し、不承認の理由の開示を求めることはできないものとします。
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
          当社は、申込者による会員登録の申請を承認した場合、申込者に対してその旨を通知します。かかる通知により、申込者による会員登録が完了し、申込者と当社との間で本規約に従った本サービスの利用に関する契約（以下「本契約」といいます。）が成立するものとします。
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
          会員登録の完了後に登録した内容の変更が生じた場合、利用者は、直ちに、登録内容の変更を申請するものとします。かかる申請については、第2項及び第3項の規定を準用します。かかる申請の遅滞又は懈怠により生じた損害（一切の損害、損失及び費用（弁護士、会計士又は税理士の報酬及び費用を含むものとします。）をいいます。以下同様とします。）については、利用者が責任を負うものとし、当社は一切の責任を負いません。
        </Box>
      </Box>
      <Typography sx={{fontSize: 18}}>第5条　（本サービスの提供）</Typography>
      <Box component='ol' sx={{listStyleType: 'decimal'}}>
        <Box component='li' sx={{textAlign: 'left'}}>
          利用者は、本サービスを利用するにあたり、必要なパーソナルコンピューター、携帯電話機、通信機器、オペレーションシステム、通信手段、電力等を、利用者の費用と責任で用意するものとします。
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
          当社は、本サービスの全部又は一部を、利用者の本人確認の有無、登録情報の内容、その他の事情に照らして、当社が認めた利用者に限定して提供することができるものとします。利用者は、当社が当該利用者による本サービスの利用を認めなかった場合でも、当社に対して理由の開示を求め、又は異議の申し立てを行うことはできません。
        </Box>
      </Box>
      <Typography sx={{fontSize: 18}}>第6条　（アカウントの管理）</Typography>
      <Box component='ol' sx={{listStyleType: 'decimal'}}>
        <Box component='li' sx={{textAlign: 'left'}}>
          利用者は、本サービスを利用するために付与されたアカウント（以下「アカウント」といいます。）を第三者に使用させず、譲渡、売買、買入等を行ってはならないものとします。当社は、アカウントの利用につき、当該アカウントの利用にかかる会員ID又はパスワードその他当該アカウントの利用につき当社が提示又は入力を求める情報又は文字列等（以下、総称して「ID等」といいます。）の一致を確認した場合、当該アカウントを保有するものとして登録された利用者が本サービスを利用したものとみなします。
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
          利用者は、自己のアカウントの管理、使用について一切の責任を負うものとし、当社に損害を与えてはならないものとします。
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
          利用者は、自己のアカウントの使用による本サービスの利用（第三者による使用を含みます。）に関して、一切の債務を負担するものとします。
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
          利用者は、利用者のハードウェア及びソフトウェアの保守を自らの責任と費用負担にて行い、利用者の端末環境を維持するものとします。
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
          利用者は、ID等が盗まれたり、第三者に使用されていることが判明した場合には、直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。
        </Box>
      </Box>
      <Typography sx={{fontSize: 18}}>第7条　（禁止事項）</Typography>
      <Typography>利用者は、本サービスの利用にあたって、以下に該当する行為又はそのおそれのある行為を行ってはならないものとします。</Typography>
      <Box component='ol' className='seven_ol'>
        <Box component='li' className='seven_li'>
          公序良俗に反する行為
        </Box>
        <Box component='li' className='seven_li'>
          法令に違反する行為
        </Box>
        <Box component='li' className='seven_li'>
          犯罪行為及び犯罪行為に結びつく行為
        </Box>
        <Box component='li' className='seven_li'>
          当社に虚偽の情報を提供する行為
        </Box>
        <Box component='li' className='seven_li'>
          本サービスによりアクセス可能な情報を改ざんする行為
        </Box>
        <Box component='li' className='seven_li'>
          本サービスの運営を妨害するおそれのある行為
        </Box>
        <Box component='li' className='seven_li'>
          本サービスの利用に関連して当社から提供される情報その他のコンテンツにつき、当社が利用を許諾した範囲を越えてこれを利用し、又は公開する行為
        </Box>
        <Box component='li' className='seven_li'>
          当社又は第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為
        </Box>
        <Box component='li' className='seven_li'>
          本サービスが通常意図しないバグを利用する動作又は通常意図しない効果を及ぼす外部ツールの利用、作成又は頒布を行う行為
        </Box>
        <Box component='li' className='seven_li'>
          コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報を送信する行為
        </Box>
        <Box component='li' className='seven_li'>
          本サービスを逆アセンブル、逆コンパイル、リバースエンジニアリング、その他本サービスのソースコードを解析する行為
        </Box>
        <Box component='li' className='seven_li'>
          本サービスを複製、譲渡、貸与又は改変する行為
        </Box>
        <Box component='li' className='seven_li'>
          他の利用者の会員IDやパスワードを不正に使用する行為
        </Box>
        <Box component='li' className='seven_li'>
          第三者に不利益を与える行為
        </Box>
        <Box component='li' className='seven_li'>
          利用者が次のいずれかに該当することとなること
          <Box component='ol' className='seven_15_ol'>
            <Box component='li' className='seven_15_li'>暴力団</Box>
            <Box component='li' className='seven_15_li'>暴力団員</Box>
            <Box component='li' className='seven_15_li'>暴力団員でなくなった時から5年を経過しない者</Box>
            <Box component='li' className='seven_15_li'>暴力団準構成員</Box>
            <Box component='li' className='seven_15_li'>暴力団関係企業</Box>
            <Box component='li' className='seven_15_li'>総会屋等、社会運動等標ぼうゴロ又は特殊知能暴力集団等</Box>
            <Box component='li' className='seven_15_li'>その他本号(a)乃至(f)に準ずるもの</Box>
            <Box component='li' className='seven_15_li'>上記(a)乃至(g)に該当する者（以下「暴力団員等」といいます。）が経営を支配していると認められる関係を有する者</Box>
            <Box component='li' className='seven_15_li'>暴力団員等が経営に実質的に関与していると認められる関係を有する者</Box>
            <Box component='li' className='seven_15_li'>自己、自社若しくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってするなど、不当に暴力団員等を利用していると認められる関係を有する者</Box>
            <Box component='li' className='seven_15_li'>暴力団員等に対して資金を提供し又は便宜を供与するなどの関与をしていると認められる関係を有する者</Box>
            <Box component='li' className='seven_15_li'>役員又は経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を有する者</Box>
          </Box>
        </Box>
        <Box component='li' className='seven_li'>
          前号記載のいずれかに該当する者への利益供与行為及びこれにつながる可能性のある行為
        </Box>
        <Box component='li' className='seven_li'>
          自ら又は第三者を利用して以下の(a)乃至(e)のいずれかに該当する行為を行うこと
          <Box component='ol' className='seven_15_ol'>
            <Box component='li' className='seven_15_li'>暴力的な要求行為</Box>
            <Box component='li' className='seven_15_li'>法的な責任を超えた不当な要求行為</Box>
            <Box component='li' className='seven_15_li'>取引に関して、脅迫的な言動をし、又は暴力を用いる行為</Box>
            <Box component='li' className='seven_15_li'>風説を流布し、偽計若しくは威力を用いて当社の信用を毀損し、又は当社の業務を妨害する行為</Box>
            <Box component='li' className='seven_15_li'>その他上記(a)乃至(d)に準ずる行為</Box>
          </Box>
        </Box>
        <Box component='li' className='seven_li'>
          その他当社が不適切と判断する行為
        </Box>
      </Box>
      <Typography sx={{fontSize: 18}}>第8条　（利用停止等）</Typography>
      利用者について次に掲げる事由が一つでも生じた場合には、当社は何らの催告も要せずに、直ちに利用者に対する本サービスの提供を停止し、又は利用者のアカウントを削除することができます。
      <Box component='ol' className='seven_ol'>
        <Box component='li' className='seven_li'>
        過去に本条に基づく利用停止又はアカウント削除の処分を受けていることが判明した場合
        </Box>
        <Box component='li' className='seven_li'>
        当社に提供した資料若しくは情報に関して虚偽の内容があった場合
        </Box>
        <Box component='li' className='seven_li'>
        本契約に違反した場合
        </Box>
        <Box component='li' className='seven_li'>
        前各号のほか、利用者に本サービスの利用を認めることが不適当であると当社が判断した場合
        </Box>
      </Box>
      <Typography sx={{fontSize: 18}}>第9条　（本サービスの利用終了）</Typography>
      <Box component='ol' sx={{listStyleType: 'decimal'}}>
        <Box component='li' sx={{textAlign: 'left'}}>
        本契約が前条により終了した場合、その他理由の如何を問わず利用者が本サービスを利用する権利を失った場合（以下「利用終了」といいます。）、利用者は、本サービスを利用することができなくなり、アカウント、コンテンツ（利用者が本サービスを通じてアクセスすることができる情報（文章、画像、動画、音声、音楽その他のサウンド、イメージ、ソフトウェア、プログラム、コードその他のデータを含みますが、これらに限られません。）の総称をいいます。以下同じ。）、その他本サービスに蓄積した情報を利用することができなくなること、当社が利用者のアカウントを削除することができることをあらかじめ承諾するものとします。
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
        当社は、利用終了により利用者に生じた不利益及び損害について一切の責任を負わず、また、利用終了後も、利用者及びその取引に関し当社が取得した一切の情報を保有・利用することができるものとします。
        </Box>
      </Box>
      <Typography sx={{fontSize: 18}}>第10条　（秘密情報）</Typography>
      <Box component='ol' sx={{listStyleType: 'decimal'}}>
        <Box component='li' sx={{textAlign: 'left'}}>
          利用者及び当社は、本サービスに関連して相手方から開示を受けた技術、営業、業務その他に関する情報のうち、相手方が特に秘密である旨を明示した情報（以下のいずれかに該当する情報を除き、以下「秘密情報」といいます。）について、相手方の同意がない限り、厳に秘密として管理し、これらを第三者に開示又は漏洩してはならないものとします。
          <Box component='ol' className='seven_ol'>
            <Box component='li' className='seven_li'>
            秘密保持義務を負うことなく既に保有している情報
            </Box>
            <Box component='li' className='seven_li'>
            秘密保持義務を負うことなく第三者から正当に入手した情報
            </Box>
            <Box component='li' className='seven_li'>
            相手方から提供を受けた情報によらず、独自に開発した情報
            </Box>
            <Box component='li' className='seven_li'>
            本契約に違反することなく、かつ、受領の前後を問わず公知となった情報
            </Box>
          </Box>
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
        前項の規定は、法令の定め又は権限ある官公署から開示の要求に基づき開示する場合については適用しないものとします。
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
        利用者及び当社は、秘密情報について、本契約上認められた範囲又は本契約の目的を達成するために必要な範囲においてのみこれらを使用することができ、その他の目的のために流用してはならないものとします。
        </Box>
      </Box>
      <Typography sx={{fontSize: 18}}>第11条　（本サービスの終了・一時的な中断）</Typography>
      <Box component='ol' sx={{listStyleType: 'decimal'}}>
        <Box component='li' sx={{textAlign: 'left'}}>
        当社は、当社の裁量で、本サービスの全部又は一部を終了し、又は一時的に中断することができるものとします。
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
        当社は、前項の規定により本サービスの運用を終了又は中断するときは、事前に、本サービス上又は当社ウェブサイト上への掲示その他当社が適当と判断する方法で利用者に通知します。ただし、緊急でやむを得ない場合は、この限りでありません。
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
        当社は、本条に基づき当社が行った措置により利用者に生じた不利益及び損害について一切の責任を負わず、また、本サービス終了後も、利用者及びその取引に関し当社が取得した一切の情報を保有・利用することができるものとします。
        </Box>
      </Box>
      <Typography sx={{fontSize: 18}}>第12条　（知的財産権等の帰属）</Typography>
      <Box component='ol' sx={{listStyleType: 'decimal'}}>
        <Box component='li' sx={{textAlign: 'left'}}>
        当社ウェブサイト並びに本サービス及び本サービス上で提供されるコンテンツ（以下「提供コンテンツ」といいます。）に関する一切の知的財産権は、当社又は当社にライセンスを許諾している者に帰属するものとします。
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
        当社は、利用者に対し、当社ウェブサイト並びに本サービス及び提供コンテンツにつき、本サービスの利用に必要な範囲における非独占的な利用を許諾します。ただし、かかる利用許諾は、第三者に対し再使用許諾する権利を含むものではなく、また、利用者に対し、当社ウェブサイト並びに本サービス及び提供コンテンツについての知的財産権、所有権類似の権利又は自由に処分しうる権利その他の権利の譲渡又は付与を意味するものではありません。
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
        当社ウェブサイト上、当社の商標、ロゴ及びサービスマーク等（以下、総称して「商標等」といいます。）が表示される場合がありますが、当社は、利用者その他の第三者に対し、商標等を譲渡し、又はその使用を許諾するものではありません。
        </Box>
      </Box>
      <Typography sx={{fontSize: 18}}>第13条　（免責）</Typography>
      <Box component='ol' sx={{listStyleType: 'decimal'}}>
        <Box component='li' sx={{textAlign: 'left'}}>
        当社は、本サービスが全ての情報端末に対応していることを保証するものではなく、本サービスの利用に供する情報端末のＯＳ又はウェブブラウザのバージョンアップ等に伴い、本サービスの動作に不具合が生じる可能性があることにつき、利用者はあらかじめ了承するものとします。当社は、かかる不具合が生じた場合に当社が行うプログラムの修正等により、当該不具合が解消されることを保証するものではありません。
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
        当社は、通信回線の障害その他事由の如何を問わず利用者が本サービスの提供を受けられなかったときでも、何ら責任を負わないものとします。
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
        何らかの理由により当社が責任を負う場合であっても、当社は、付随的損害、間接損害、特別損害及び逸失損害については、賠償する責任を負わないものとします。
        </Box>
      </Box>
      <Typography sx={{fontSize: 18}}>第14条　（損害賠償）</Typography>
      <Box component='ol' sx={{listStyleType: 'none'}}>
        <Box component='li' sx={{textAlign: 'left'}}>
        利用者は、本契約に違反することにより又は本サービスの利用に関連して当社に損害を与えた場合、当社に対し当該損害を賠償しなければなりません。
        </Box>
      </Box>
      <Typography sx={{fontSize: 18}}>第15条　（本契約の譲渡等）</Typography>
      <Box component='ol' sx={{listStyleType: 'none'}}>
        <Box component='li' sx={{textAlign: 'left'}}>
        利用者は、当社の書面による事前の承諾がある場合を除き、本契約に基づく権利若しくは義務、又は本契約上の地位について、第三者への譲渡、承継、担保設定、その他一切の処分をすることはできません。
        </Box>
      </Box>
      <Typography sx={{fontSize: 18}}>第16条　（分離可能性）</Typography>
      <Box component='ol' sx={{listStyleType: 'none'}}>
        <Box component='li' sx={{textAlign: 'left'}}>
        本契約のいずれかの条項又はその一部が、法令等により無効又は執行不能と判断された場合であっても、本契約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有し、当社及び利用者は、当該無効若しくは執行不能の条項又は部分を適法とし、執行力を持たせるために必要な範囲で修正し、当該無効若しくは執行不能な条項又は部分の趣旨並びに法律的及び経済的に同等の効果を確保できるように努めるものとします。
        </Box>
      </Box>
      <Typography sx={{fontSize: 18}}>第17条　（準拠法及び合意管轄）</Typography>
      <Box component='ol' sx={{listStyleType: 'decimal'}}>
        <Box component='li' sx={{textAlign: 'left'}}>
        本契約は日本法に準拠するものとします。
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
        本契約に起因し又は関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
        </Box>
      </Box>
      <Typography sx={{textAlign: 'right'}}>以上</Typography>
      <Typography>2024年4月1日制定</Typography>
    </Box>
  );
};