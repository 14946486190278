import React from 'react'
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography} from '@mui/material';
import Cookies from 'js-cookie';

export const SendEmail = () =>{
  const navigate = useNavigate();
  const signedIn = Cookies.get('isSignedIn');

  const onClickCancel = () =>{
    if(signedIn){
      navigate('/login2');
    }else{
      navigate('/login');
    }
  }

  return (
    <Box
      sx={{
        width: {md: '70%'},
        maxHeight: '3500px',
        margin: '0 auto',
        pr: 1,
        pl: 1,
        minWidth: {md: '510px'},
        pt: 1,
        pb: 1,
        mt: {md: 10, xs: 2}
      }}
    >
      <Typography>ご入力いただいたメールアドレスにメールを送信しました。</Typography>
      <Typography>メールに記載されたURLをクリックして認証に進んでください。</Typography>
      <br/>
      <Typography>※メールの設定状況によってはメールが届かない場合があります。</Typography>
      <Typography>【support@factoru.chushokigyo-support.or.jp】を受信できる設定になっているかご確認ください。</Typography>
      <Box sx={{mt: 3}}>
        <Button
          variant='contained'
          onClick={() => onClickCancel()}
          sx={{borderRadius: 5, minWidth: '155px',  width: {md: '20%', xs: '100%'}, fontWeight: 'bold', mb: {xs: 1, md: 2}, ml: 1}}
        >
          ログイン
        </Button>
      </Box>
    </Box>
  )
}