import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

export const PageTitle = () =>{
  const [pageName, setPageName] = useState('');
  const location = useLocation();

  useEffect(() => {
    let pathname = location.pathname;
    if(pathname.indexOf('/profile/edit/authentication') >= 0){
      pathname='/profile/edit/authentication';
    }else if(pathname.indexOf('/profile/edit/completed') >= 0){
      pathname='/profile/edit/completed';
    }else if(pathname=='/profile/top' || pathname.indexOf('/profile/top') >= 0){
      pathname='/profile';
    }else if(pathname.indexOf('/ar_info/completed') >= 0){
      pathname="/ar_info/completed";
    }else if(pathname.indexOf('/status/detail') >= 0){
      pathname="/status/detail";
    }else if(pathname.indexOf('/add_file') >= 0){
      if(pathname.indexOf('list') < 0 && pathname.indexOf('confirm') < 0 && pathname.indexOf('completed') < 0){
        pathname = '/add_file';
      }
    }

    switch(pathname){
      case '/login':
        setPageName('ログイン');
        break;
      case '/login/password/resetting':
      case '/login/password/resetting/resend':
      case '/login/password/resetting/confirm':
        setPageName('パスワードの再設定');
        break;
      case '/login/password/resetting/completed':
        setPageName('パスワード変更完了');
        break;
      case '/signup/completed':
        setPageName('');
        break;
      case '/signup':
        setPageName('新規アカウント作成');
        break;
      case '/signup/info':
      case '/member':
        setPageName('アカウント登録フォーム');
        break;
      case '/signin':
        setPageName('新規アカウント作成');
        break;
      case '/':
      case '/home':
      case '/admin/home':
        setPageName('トップページ');
        break;
      case '/profile':
      case '/admin/profile':
        setPageName('ユーザー情報');
        break;
      case '/profile/edit/completed':
        setPageName('変更完了');
        break;
      case '/ar':
      case '/admin/ar':
        setPageName('売掛先情報');
        break;
      case '/ar_register':
      case '/admin/ar_register':
        setPageName('売掛先の追加');
        break;
      case '/sold_to':
        setPageName('売掛先の登録');
        break;
      case '/ar_info/completed':
        setPageName('登録完了');
        break;
      case '/ar_list':
      case '/admin/ar_list':
        setPageName('登録済み売掛先一覧');
        break;
      case '/top/factoring':
      case '/admin/top/factoring':
        setPageName('ファクタリング申請');
        break;
      case '/apply/factoring_new':
      case '/admin/apply/factoring_new':
        setPageName('ファクタリング申請');
        break;
      case '/apply/factoring_confirm':
      case '/admin/apply/factoring_confirm':
        setPageName('');
        break;
      case '/factoring/app/draft':
      case '/admin/factoring/app/draft':
        setPageName('下書き');
        break;
      case '/factoring/app_list':
      case '/admin/factoring/app_list':
        setPageName('申請履歴');
        break;
      case '/apply_copy/factoring/confirm':
      case '/admin/apply_copy/factoring/confirm':
        setPageName('');
        break;
      case '/add_file_list':
      case '/admin/add_file_list':
        setPageName('追加書類のアップロード');
        break;
      case '/status':
      case '/admin/status':
        setPageName('申請履歴');
        break;
      case '/status/detail':
      case '/admin/status/detail':
        setPageName('申請内容');
        break;
      case '/add_file':
      case '/admin/add_file':
        setPageName('書類アップロード');
        break;
      case '/due_date_list':
      case '/admin/due_date_list':
        setPageName('入金予定日リスト');
        break;
      case '/terms_of_use':
      case '/admin/terms_of_use':
        setPageName('利用規約');
        break;
      case '/faq':
      case '/admin/faq':
        setPageName('FAQ');
        break;
      case '/cancellation_of_membership':
      case '/admin/cancellation_of_membership':
        setPageName('退会手続きフォーム');
        break;
      case '/cancellation_of_membership/completed':
      case '/admin/cancellation_of_membership/completed':
        setPageName('退会完了');
        break;
      case '/privacy':
      case '/admin/privacy':
        setPageName('プライバシーポリシー');
        break;
      default:
        setPageName('');
    }
  }, [location.pathname])

  return (
    <Box >
      <Typography sx={{fontSize: 20, mb: 1, textAlign: 'left'}}>
        {pageName}
      </Typography>
      {Cookies.get('isSignedIn') && location.pathname == '/profile/top'? 
        <Box sx={{ pl: 0, mb: -2, textAlign: 'left', width: '100%', display: {md: 'block', xs: 'none'}}}>
          <Typography  sx={{ color: '#000000', display: {md: 'block', xs: 'none'}, overflow: 'hidden', fontSize: '12px'}}>ユーザーID</Typography>
          <Typography sx={{ color: '#000000', display: {md: 'block', xs: 'none'}, overflowWrap: 'anywhere', fontSize: '12px', width: '50vw'}}>{Cookies.get('_email')}</Typography>
        </Box>
        : Cookies.get('_admin_access_token') && location.pathname == '/admin/profile/top' ?
        <Box sx={{ pl: 0, mb: -2, textAlign: 'left', width: '100%', display: {md: 'block', xs: 'none'}}}>
          <Typography  sx={{ color: '#000000', display: {md: 'block', xs: 'none'}, overflow: 'hidden', fontSize: '12px'}}>ユーザーID</Typography>
          <Typography sx={{ color: '#000000', display: {md: 'block', xs: 'none'}, overflowWrap: 'anywhere', fontSize: '12px', width: '50vw'}}>{Cookies.get('_admin_user_email')}</Typography>
        </Box>
        :<></>
      }
    </Box>
  );
};