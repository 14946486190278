import React, { useCallback, useEffect, useRef, useState } from 'react';
import {Box, Divider, Typography, Link as MuiLink, Grid  } from '@mui/material';
import { Link, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { FaqContent } from '../components/FaqContent';
import { getProfile } from '../lib/api/profile';
import { Element, scroller} from 'react-scroll';
import { ErrorModal } from '../components/ErrorModal';
import { ConfirmModal } from '../components/ConfirmModal';
import { getDraftOpportunityByUserId } from '../lib/api/draftOpportunity';

interface Draft {
  draft: string;
  id: string;
}

export const Faq = () =>{
  const navigate = useNavigate();
  const [lastOppState, setLastOppState] = useState('');
  const [tradingCondition, setTradingCondition] = useState('');
  const refFactoring = useRef();
  const refKeiyaku = useRef();
  const refRiyou = useRef();
  const refSousa = useRef();
  const refHonnin = useRef();
  const parentRef = useRef();
  const [contactInfo, setContactInfo] = useState({bankname: '', bankinfo: '', accountname: '', accountnamekana: '', phone: ''});
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState<any>();
  const [draft, setDraft] = useState<Draft>({draft: '', id: ''});

  const handleGetProfile = async () =>{
    const res = await getProfile();
    const res2 = await getDraftOpportunityByUserId();
    if(res.status === 200){
      setLastOppState(res.data.record.lastOppStatus);
      setTradingCondition(res.data.record.tradingCondition);
    }
    if(res2.status === 200){
      setConfirmMessage(<><Typography>審査申し込みの下書きが残っていますがよろしいでしょうか？</Typography></>)
      setDraft(res2.data.record);
    }
  }

  useEffect(() => {
    const contact = JSON.parse(Cookies.get('_contact'));
    handleGetProfile();
    setContactInfo(contact);
  }, [])

  const linkDelete = () =>{
    if(lastOppState && lastOppState != '見込み' && lastOppState != 'NG' && lastOppState != 'キャンセル' && lastOppState != '実行済み'){
      setErrorMessage(<Typography>申請中は退会できません</Typography>);
      handleOpenErrorModal();
      return;
    }
    if(tradingCondition == '契約中'){
      setErrorMessage(<Typography>ご契約中は退会できません</Typography>);
      handleOpenErrorModal();
      return;
    }
    if(draft && draft.id){
      setConfirmModalOpen(true);
      return;
    }
    navigate(`/cancellation_of_membership/${Cookies.get('currentUserId')}`)
  }

  const handleOpenErrorModal = () =>{
    setErrorModalOpen(!errorModalOpen);
  }

  const handleOkClick = () =>{
    setConfirmModalOpen(false);
    navigate(`/cancellation_of_membership/${Cookies.get('currentUserId')}`);
  }

  const handleCancelClick = () =>{
    setConfirmModalOpen(false);
    return;
  }

  const handleCloseConfirmModal = () =>{
    setConfirmModalOpen(false);
    return;
  }

  const scroll = (elementName, ref) =>{
    const titleRect = ref.current.getBoundingClientRect();
    const margin = window.innerWidth <= 768 ? 65 : 30;
    const offset = - (titleRect.height * 1.5) - margin;
    scroller.scrollTo(elementName, {
      smooth: true,
      offset: offset
    })
  }

  const scrollFactoring = useCallback((evt)=>{
    scroll('factoringElement', refFactoring);
  }, []);
  const scrollKeiyaku = useCallback((evt)=>{
    scroll('keiyakuElement', refKeiyaku);
  }, []);
  const scrollHonnin = useCallback((evt)=>{
    scroll('honninElement', refHonnin);
  }, []);
  const scrollRiyou = useCallback((evt)=>{
    scroll('riyouElement', refRiyou);
  }, []);
  const scrollSousa = useCallback((evt)=>{
    scroll('sousaElement', refSousa);
  }, []);

  return (
    <Box
      ref={parentRef}
      sx={{
      backgroundColor: "#FFFFFF", 
      p: 2,
      borderRadius: 2
    }}>
      <Box sx={{
        display: {md: 'flex'}
      }}>
        <Box
          onClick={scrollFactoring}
          sx={[
            {
              border: '1px solid #000',
              width: {xs: '100%', md: "33%"},
              minHeight: {md: '100px', xs: '40px'},
              maxHeight: {md: '100px', xs: '40px'},
              borderRadius: {md: 5 , xs: 3},
              cursor: 'pointer',
              position: 'relative',
              mr: {md: 1},
              mt: {xs: 2},
              boxShadow: '3px 3px 0px 0px rgba(0, 0, 0, 0.15)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            },
            {'&:hover': {
              backgroundColor: '#F0F0F0'
            }}
          ]}
        >
          <Grid>
            <Typography sx={{fontWeight: 'bold', pt: 0}}>ファクトルのサービスについて</Typography>
          </Grid>
          <Grid>
            <Box component="img" src="/icons/icon_arrow.svg" sx={{position: 'absolute', left: {md: 'calc((100% - 23px)/2)', xs: 'calc((100% - 40px))'}, top: {md: 60, xs: 10}, display: {md: 'none', xs: 'block'}}}/>
          </Grid>
        </Box>
        <Box
          onClick={scrollKeiyaku}
          sx={[
            {
              border: '1px solid #000',
              width: {xs: '100%', md: "33%"},
              minHeight: {md: '100px', xs: '40px'},
              maxHeight: {md: '100px', xs: '40px'},
              borderRadius: {md: 5 , xs: 3},
              cursor: 'pointer',
              position: 'relative',
              mr: {md: 1},
              mt: {xs: 2},
              boxShadow: '3px 3px 0px 0px rgba(0, 0, 0, 0.15)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            },
            {'&:hover': {
              backgroundColor: '#F0F0F0'
            }}
          ]}
        >
          <Grid>
            <Typography sx={{fontWeight: 'bold', pt: 0, display: {xs: 'block', md: 'none'}}}>お申し込み・契約について</Typography>
            <Typography sx={{fontWeight: 'bold', pt: 0, display: {xs: 'none', md: 'block'}}}>お申し込み・</Typography>
            <Typography sx={{fontWeight: 'bold', display: {xs: 'none', md: 'block'} }}>契約について</Typography>
          </Grid>
          <Grid>
            <Box component="img" src="/icons/icon_arrow.svg" sx={{position: 'absolute', left: {md: 'calc((100% - 23px)/2)', xs: 'calc((100% - 40px))'}, top: {md: 60, xs: 10}, display: {md: 'none', xs: 'block'}}}/>
          </Grid>
        </Box>
        <Box
          onClick={scrollHonnin}
          sx={[
            {
              border: '1px solid #000',
              width: {xs: '100%', md: "33%"},
              minHeight: {md: '100px', xs: '40px'},
              maxHeight: {md: '100px', xs: '40px'},
              borderRadius: {md: 5 , xs: 3},
              cursor: 'pointer',
              position: 'relative',
              mr: {md: 1},
              mt: {xs: 2},
              boxShadow: '3px 3px 0px 0px rgba(0, 0, 0, 0.15)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            },
            {'&:hover': {
              backgroundColor: '#F0F0F0'
            }}
          ]}
        >
          <Grid>
            <Typography sx={{fontWeight: 'bold', pt: 0, display: {xs: 'block', md: 'none'}}}>オンライン本人確認について</Typography>
            <Typography sx={{fontWeight: 'bold', pt: 0, display: {xs: 'none', md: 'block'}}}>オンライン</Typography>
            <Typography sx={{fontWeight: 'bold', display: {xs: 'none', md: 'block'}}}>本人確認について</Typography>
          </Grid>
          <Grid>
            <Box component="img" src="/icons/icon_arrow.svg" sx={{position: 'absolute', left: {md: 'calc((100% - 23px)/2)', xs: 'calc((100% - 40px))'}, top: {md: 60, xs: 10}, display: {md: 'none', xs: 'block'}}}/>
          </Grid>
        </Box>
        <Box
          onClick={scrollSousa}
          sx={[
            {
              border: '1px solid #000',
              width: {xs: '100%', md: "33%"},
              minHeight: {md: '100px', xs: '40px'},
              maxHeight: {md: '100px', xs: '40px'},
              borderRadius: {md: 5 , xs: 3},
              cursor: 'pointer',
              position: 'relative',
              mr: {md: 1},
              mt: {xs: 2},
              boxShadow: '3px 3px 0px 0px rgba(0, 0, 0, 0.15)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            },
            {'&:hover': {
              backgroundColor: '#F0F0F0'
            }}
          ]}
        >
          <Grid>
            <Typography sx={{fontWeight: 'bold', pt: 0}}>ファクトルの操作方法について</Typography>
          </Grid>
          <Grid>
            <Box component="img" src="/icons/icon_arrow.svg" sx={{position: 'absolute', left: {md: 'calc((100% - 23px)/2)', xs: 'calc((100% - 40px))'}, top: {md: 60, xs: 10}, display: {md: 'none', xs: 'block'}}}/>
          </Grid>
        </Box>
      </Box>
      <Box sx={{mb: 2, mt: 4}} ref={refFactoring}>
        <Element name="factoringElement"></Element>
        <Typography sx={{textAlign: 'center', fontSize: '20px', fontWeight: 'bold'}} id="factoring">
          <Box component='span' sx={{
            backgroundColor: '#DF7A99',
            width: '7px',
            height: '30px',
            display: 'inline-block',
            border: '1px solid #DF7A99',
            transform: 'rotate(45deg)',
            borderRadius: '5px',
            mb: '-6px',
            mr: 3
          }} />
          ファクトルのサービスについて
        </Typography>
      </Box>
      <Divider />
      <FaqContent
        subject="ファクタリングとは？"
        messages={[
          <Typography>『ファクタリング』とは、お客様がお持ちの売掛金を買い取り、売掛先からの入金日より前に早期資金化するサービスです。最短即日で資金調達できるため、融資の審査中のつなぎ資金としてもご利用いただけます。</Typography>,
          <Typography>すでに仕事が完了している売掛金であれば買取対象になります。回収済みの売掛金や、支払いが遅れている売掛金は対象外になりますので、ご注意ください。</Typography>,
          <Typography>ファクタリングについて<Box component='a' sx={{wordBreak: 'break-all', color: '#DF7A99', textDecoration: 'underline'}}  className='link'　target='_blank' href='https://chushokigyo-support.or.jp/column/factoring/factoring/'>詳しく知りたい方はこちらのページ</Box>をご覧ください。</Typography>
        ]}
      />
      <Divider />
      <FaqContent
        subject="手数料はいくらですか？"
        messages={[
          <Typography>手数料は1.5％～となります。</Typography>,
          <Typography>ただし、売却いただく売掛債権の金額や支払期日など、債権の内容により手数料は異なりますのでご了承ください。</Typography>
        ]}
      />
      <Divider />
      <FaqContent
        subject="個人事業主も利用できる？"
        messages={[
          <Typography>ご利用可能です。</Typography>
        ]}
      />
      <Divider />
      <FaqContent
        subject="振込までどれくらい？当日に入金される？"
        messages={[
          <Typography>ファクトルでは、独自のAIを活用しているため、お申込みから入金まで最短40分です。必要な書類を事前にご準備いただくことをお勧めいたします。</Typography>,
          <Typography>契約が完了しましたら、原則当日中にお振込みいたしますが、契約締結が17時以降の場合、銀行によっては翌日に反映される場合もございます。</Typography>
        ]}
      />
      <Divider />
      <FaqContent
        subject="買取金額に上限はある？"
        messages={[
          <Typography>買取金額の上限は設けておりません。</Typography>,
          <Typography>※システムの都合上、1億円以上の買取をご希望の方は恐れ入りますが、<Box component='a' sx={{wordBreak: 'break-all', color: '#DF7A99', textDecoration: 'underline'}}  className='link'　target='_blank' href='https://chushokigyo-support.or.jp/'>HP</Box>よりお問い合わせください</Typography>
        ]}
      />
      <Divider />
      <FaqContent
        subject="給与ファクタリングを申し込みたい。"
        messages={[
          <Typography>恐れ入りますが、ファクトルでは給与ファクタリングは取り扱っておりません。</Typography>
        ]}
      />
      <Divider />
      <Box sx={{mb: 2, mt: 3}} ref={refKeiyaku}>
        <Element name="keiyakuElement"></Element>
        <Typography sx={{textAlign: 'center', fontSize: '20px', fontWeight: 'bold'}}>
          <Box component='span' sx={{
            backgroundColor: '#51A7F2',
            width: '7px',
            height: '30px',
            display: 'inline-block',
            border: '1px solid #51A7F2',
            transform: 'rotate(45deg)',
            borderRadius: '5px',
            mb: '-6px',
            mr: 3
          }} />
          お申し込み・契約について
        </Typography>
      </Box>
      <Divider/>
      <FaqContent
        subject="審査結果はいつわかる？"
        messages={[
          <Typography>独自のAIを活用することで、審査は最短10分でご提示しております。</Typography>,
          <Typography>審査結果は、マイページの『<MuiLink sx={{wordBreak: 'break-all', cursor: 'pointer'}} component={Link} to={`/status`} underline="always">ファクタリング申請履歴</MuiLink>』よりご確認いただけます。</Typography>
        ]}
      />
      <Divider />
      <FaqContent
        subject="利用時の必要書類は？"
        messages={[
          <Typography>審査に必要な書類は基本的に以下2つです。</Typography>,
          <Typography>・通帳のコピー（表紙付き、3か月分）</Typography>,
          <Typography>・売掛金に関する書類（請求書・契約書など）</Typography>,
          <Typography>※売掛債権の内容等によっては追加の書類提出をお願いする場合がございます。</Typography>
        ]}
      />
      <Divider />
      <FaqContent
        subject="審査に通らなかった理由を知りたい。"
        messages={[
          <Typography>大変恐れ入りますが、審査結果の詳細は開示しておらず、お問い合せいただきましてもお答えできかねます。</Typography>
        ]}
      />
      <Divider />
      <FaqContent
        subject="本人確認に必要な書類を教えてください。"
        messages={[
          <Typography>運転免許証、マイナンバーカード、在留カード、住民基本台帳カード、運転経歴証明書、特別永住者証明書、パスポート、健康保険証のいずれかをご準備ください。</Typography>,
          <Typography>なお、パスポート・健康保険証をご提出の場合、住所を確認できる書類の提出も必要になります。</Typography>
        ]}
      />
      <Divider />
      <FaqContent
        subject="売掛先から入金がなく、ファクトルに送金できません。"
        messages={[
          <Typography>売掛先からの入金が遅れる、入金がないことが分かったら速やかに担当者までご連絡ください。</Typography>
        ]}
      />
      <Divider />
      <FaqContent
        subject="3者間契約を申し込みたい。"
        messages={[
          <Typography>ファクトルでは2者間契約のみ受け付けております。</Typography>,
          <Typography>お手数ですが、3者間契約をご希望の場合は<Box component='a' sx={{wordBreak: 'break-all', color: '#DF7A99', textDecoration: 'underline'}}  className='link'　target='_blank' href='https://chushokigyo-support.or.jp/inquiry/'>こちらのフォーム</Box>からお問い合わせください。</Typography>
        ]}
      />
      <Divider/>
      <Box sx={{mb: 2, mt: 3}} ref={refHonnin}>
        <Element name="honninElement"></Element>
        <Typography sx={{textAlign: 'center', fontSize: '20px', fontWeight: 'bold'}}>
          <Box component='span' sx={{
            backgroundColor: '#51A7F2',
            width: '7px',
            height: '30px',
            display: 'inline-block',
            border: '1px solid #51A7F2',
            transform: 'rotate(45deg)',
            borderRadius: '5px',
            mb: '-6px',
            mr: 3
          }} />
          オンライン本人確認について
        </Typography>
      </Box>
      <Divider/>
      <FaqContent
        subject="本人確認せずに申請したい。"
        messages={[
          <Typography>ファクタリング申請を行う際は、代表者様の身分証のご提出を必須としております。『<MuiLink sx={{wordBreak: 'break-all', cursor: 'pointer'}}  component={Link} to={`/profile/top`} underline="always">オンライン本人確認</MuiLink>』を実施のうえ、申請に進んでください。</Typography>,
        ]}
      />
      <Divider/>
      <FaqContent
        subject="オンライン本人確認の申請は何回できる？"
        messages={[
          <Typography>オンライン本人確認は、複数回連続で否認されると申請できなくなります。</Typography>,
          <Typography>お手数ですが、万が一、申請できなくなった場合はお電話にてご相談ください。</Typography>
        ]}
      />
      <Divider/>
      <FaqContent
        subject="本人確認が否認された理由を知りたい。"
        messages={[
          <Typography>否認になった場合、ご登録のメールアドレスに否認理由をお送りしております。</Typography>,
          <Typography>ご確認のうえ再度『<MuiLink sx={{wordBreak: 'break-all', cursor: 'pointer'}}  component={Link} to={`/profile/top`} underline="always">オンライン本人確認</MuiLink>』を実施してください。</Typography>,
          <Typography>※身分証やセルフィー撮影のポイントについて<Box component='a' sx={{wordBreak: 'break-all', color: '#DF7A99', textDecoration: 'underline'}}  className='link'　target='_blank' href='https://biz.trustdock.io/verificationguide/app/ekyc-e'>こちら</Box>をご確認ください。</Typography>
        ]}
      />
      <Divider/>
      <FaqContent
        subject="セルフィーが否認された理由が知りたい。"
        messages={[
          <Typography>セルフィーは、その場で撮影した顔写真のご提出が必要です。</Typography>,
          <Typography>証明写真などを提出していないかご確認ください。</Typography>,
          <Typography></Typography>,
          <Typography>※以下の理由の場合、その場で撮っていない写真の可能性があります。</Typography>,
          <Typography>-サングラス・帽子などで顔が隠れている</Typography>,
          <Typography>-顔がない</Typography>,
          <Typography>-その他</Typography>,
          <Typography>-セルフィーと公的身分証の顔写真が一致しない　</Typography>
        ]}
      />
      <Divider/>
      <FaqContent
        subject="その場で撮ったセルフィーを送ったが否認された。"
        messages={[
          <Typography>その場で撮った写真でも以下のような場合は否認となります。</Typography>,
          <Typography>-顔がサングラスや帽子で隠れている</Typography>,
          <Typography>-写真が不鮮明で顔が見えない</Typography>,
          <Typography>-背景に別の人が写っている</Typography>,
          <Typography>-写真の加工を行っている（写真の切り抜きなど）</Typography>,
          <Typography></Typography>,
          <Typography>顔が鮮明に写っているか、切り抜きなどの加工をしていないかなどを再度ご確認ください。</Typography>
        ]}
      />
      <Divider/>
      <Box sx={{mb: 2, mt: 3}} ref={refSousa}>
        <Element name="sousaElement"></Element>
        <Typography sx={{textAlign: 'center', fontSize: '20px', fontWeight: 'bold'}}>
          <Box component='span' sx={{
            backgroundColor: '#DF7A99',
            width: '7px',
            height: '30px',
            display: 'inline-block',
            border: '1px solid #DF7A99',
            transform: 'rotate(45deg)',
            borderRadius: '5px',
            mb: '-6px',
            mr: 3
          }} />
          ファクトルの操作方法について
        </Typography>
      </Box>
      <Divider/>
      <FaqContent
        subject="ファクタリング申請した内容を修正したい。"
        messages={[
          <Typography>申請済みの内容を一部修正したい場合は、お手数ですが1度申請をキャンセルし、内容を修正のうえ再申請してください。</Typography>,
          <Typography>審査可決後に内容を変更したい場合には、お手数ですが担当者までご連絡ください。</Typography>
        ]}
      />
      <Divider/>
      <FaqContent
        subject="追加で書類を送りたい。"
        messages={[
          <Typography>追加の書類提出はマイページ内の『<MuiLink sx={{wordBreak: 'break-all', cursor: 'pointer'}}  component={Link} to={`/add_file_list`} underline="always">追加書類アップロード</MuiLink>』ページよりご提出ください。</Typography>
        ]}
      />
      <Divider/>
      <FaqContent
        subject="一部の売掛金のみファクタリング申請したい。"
        messages={[
          <Typography>請求書の一部の金額のみの申請も可能です。</Typography>,
          <Typography>『<MuiLink sx={{wordBreak: 'break-all', cursor: 'pointer'}}  component={Link} to={`/apply/factoring_new`} underline="always">ファクタリング申請</MuiLink>』ページの申請フォームよりご希望の金額を入力のうえ申請してください。</Typography>
        ]}
      />
      <Divider/>
      <FaqContent
        subject="パスワードの変更方法を教えてください。"
        messages={[
          <Typography>パスワード再設定は<MuiLink sx={{wordBreak: 'break-all', cursor: 'pointer'}}  component={Link} to={`/login/password/resetting`} underline="always">こちら</MuiLink></Typography>
        ]}
      />
      <Divider/>
      <FaqContent
        subject="退会方法は？"
        messages={[
          <Typography>退会手続きは<MuiLink sx={{wordBreak: 'break-all', cursor: 'pointer'}} onClick={linkDelete} underline="always">こちら</MuiLink></Typography>
        ]}
      />
      <Divider/>
      <FaqContent
        subject="このページで解決できませんでした。"
        messages={[
          <Typography sx={{display: {xs: 'block', md: 'none'}}}>FAQを読んでも解決しない場合はお電話にてご連絡ください。</Typography>,
          <Typography sx={{display: {xs: 'block', md: 'none'}}}>
              <Box component='a' sx={{wordBreak: 'break-all', color: '#DF7A99', textDecoration: 'underline'}}  className='link'　target='_blank' href='tel:0120-885-010'>0120-885-010</Box>
          （対応時間は平日9：30～18：00です）</Typography>,
          <Typography sx={{display: {xs: 'none', md: 'block'}}}>FAQを読んでも解決しない場合はお電話にてご連絡ください。</Typography>,
          <Typography sx={{display: {xs: 'none', md: 'block'}}}>
              0120-885-010（対応時間は平日9：30～18：00です）</Typography>
        ]}
      />
      <Divider/>
      <ErrorModal message={errorMessage} modalOpen={errorModalOpen}></ErrorModal>
      <ConfirmModal message={confirmMessage} modalOpen={confirmModalOpen} cancelClick={handleCancelClick} okClick={handleOkClick} closeClick={handleCloseConfirmModal}></ConfirmModal>
    </Box>
  );
};