import React, { useEffect, useState } from 'react';
import { Typography, Box, Button } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { ErrorModal } from './ErrorModal';

export const BottomButtons = () =>{
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const location = useLocation();
  const [contactInfo, setContactInfo] = useState({bankname: '', bankinfo: '', accountname: '', accountnamekana: '', phone: ''});
  const [isViewContact, setIsViewContact] = useState(false);
  const [isViewMessage, setIsViewMessage] = useState(false);

  useEffect(() =>{
    if (Cookies.get('isSignedIn') == 'true' && Cookies.get('isSMSVerified') == 'true'){
      const contact = JSON.parse(Cookies.get('_contact'));
      if(contact){
        setContactInfo(contact);
        setIsViewContact(true);
      }
    }else{
      setIsViewContact(false);
    }
    if(location.pathname == '/profile/top'){
      setIsViewMessage(true);
    }else{
      setIsViewMessage(false);
    }
  }, [location.pathname]);

  const handleOpenModal = () =>{
    setModalOpen(!modalOpen);
  }

  return (
    <>
      {Cookies.get('isSignedIn') && Cookies.get('isSignedIn') == 'true' && Cookies.get('isSMSVerified') == 'true' ?
        <Box sx={{pb: 20, display: {xs: 'block', md: 'none'}}}>
          <Box sx={{mt: 2, pl: 3, pr: 3, display: {xs: 'block', md: 'none'}}}>
            <Box
              sx={{width: "100%", mb: 2}}
            >
              <Box
                onClick={() => navigate('/profile/top')}
                sx={{
                  width: "100%",
                  backgroundColor: "#DF7A99",
                  color: "#FFFFFF",
                  pt: 2,
                  pb: 3,
                  pl: 1,
                  pr: 1
                }}
              >
                <Typography sx={{width: "100%", textAlign: 'center', fontWeight: '900', fontSize: '18px'}}>PROFILE</Typography>
                <Typography sx={{width: "100%", textAlign: 'center'}}>マイプロフィール</Typography>
              </Box>
            </Box>
            <Box
              sx={{width: "100%", mb: 2}}
            >
              <Box
                onClick={() => navigate('/top/factoring')}
                sx={{
                  width: "100%",
                  backgroundColor: "#DF7A99",
                  color: "#FFFFFF",
                  pt: 2,
                  pb: 2,
                  pl: 1,
                  pr: 1
                }}
              >
                <Typography sx={{width: "100%", textAlign: 'center', fontWeight: '900', fontSize: '18px'}}>FACTORING</Typography>
                <Typography sx={{width: "100%", textAlign: 'center'}}>ファクタリング申請</Typography>
              </Box>
            </Box>
            <Box
              sx={{width: "100%"}}
            >
              <Box
                onClick={() => navigate('/add_file_list')}
                sx={{
                  width: "100%",
                  backgroundColor: "#DF7A99",
                  color: "#FFFFFF",
                  pt: 2,
                  pb: 2,
                  pl: 1,
                  pr: 1
                }}
              >
                <Typography sx={{width: "100%", textAlign: 'center', fontWeight: '900', fontSize: '18px'}}>UPLOAD</Typography>
                <Typography sx={{width: "100%", textAlign: 'center'}}>追加資料提出</Typography>
              </Box>
            </Box>
          </Box>
          {isViewMessage ?
          <Box sx={{mt: 1, mr: 3, ml: 3}}>
            <Typography sx={{display: {xs: 'block', md: 'none'}, fontSize: '10px', textAlign: 'left'}}>※お客様からのご要望・最新の登記情報に基づきユーザー情報または売掛先情報を変更等する場合がございます。</Typography>
            <Typography sx={{display: {xs: 'block', md: 'none'}, fontSize: '10px', textAlign: 'left'}}>変更内容に異議等がございましたら<Box component='a' sx={{wordBreak: 'break-all'}}  className='link'　target='_blank' href='https://chushokigyo-support.or.jp/inquiry/'>こちら</Box>よりお問い合わせください。</Typography>
          </Box> :
          <></>
          }
          {isViewContact ? 
          <>
            {contactInfo.bankname ?
            <Button 
              variant='contained'
              color='secondary'
              onClick={handleOpenModal}
              sx={{display: {xs: 'initial', md: 'none'}, borderRadius: 5, width: '45%', fontWeight: 'bold', mt: {xs: 5, md: 0}, mb: {xs: 1, md: 0}}}
            >
              振込先確認
            </Button>
            :
            <></>
            }
            {contactInfo.bankname ?
            <ErrorModal message={<><Typography>＜振込先口座＞</Typography><Typography>{contactInfo.bankname}</Typography><Typography>{contactInfo.bankinfo}</Typography><Typography>{contactInfo.accountnamekana}</Typography></>} modalOpen={modalOpen} />
            :
            <></>
            }
            {contactInfo.phone && contactInfo.phone != '-' ? 
            <Button 
              component={Link}
              color='primary'
              variant='contained'
              to={`tel:${contactInfo.phone}`}
              sx={{display: {xs: 'inline-block', md: 'none'}, height: '40px', borderRadius: 5, width: '45%', fontWeight: 'bold', mt: {xs: 5, md: 0}, mb: {xs: 1, md: 0}}}
            >
              電話する
            </Button>
            :
            <></>
            }
          </>
          :
          <></>
          }
        </Box>
      :
      <></>
      }
    </>
  );
};