import React from 'react';
import { Box, Button, Typography} from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getAccountsReceivable } from '../../lib/api/guest';

type SalesTo = {
  name: string,
  id: string
}

export const GuestSalesToSuccess = () =>{
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const accName = query.get('Name');
  const accId = query.get('Id');
  const [salesTo, setSalesTo] = useState<SalesTo[]>([]);
  const navigate = useNavigate();

  const handleGetAccounts = async (pid: string) => {
    try{
      const res = await getAccountsReceivable(pid, accId);
      if (res.status === 200 && res.data.record != null) {
        setSalesTo([res.data.record]);
      }
    }catch(e){
      console.log(e);
    }
  }
  const {id} = useParams();
  useEffect(() => {
    if(id){
      handleGetAccounts(id);
    }
  }, [])

  const onclickNew = () =>{
    navigate("/sold_to?Id=" + accId + "&Name=" + accName);
  }

  return (
    <Box
      sx={{
        backgroundColor: '#F0EFEA',
        width: {md: '60%'},
        height: {md: '200px'},
        margin: '0 auto',
        borderRadius: '5px',
        minWidth: {md: '570px'},
        mt: {md: 10, xs: 2},
        pr: 2,
        pl: 2,
        pt: 1,
        pb: 1
      }}
    >
      <Typography sx={{fontWeight: 'bold', fontSize: 17, pt: 5}}>
        下記売掛先を登録しました。
      </Typography>
      { salesTo.map((st) =>{
        return <Typography>{st.name}</Typography>
      })}
      <Box sx={{mt: 3}}>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>{onclickNew()}}
          sx={{borderRadius: 5, width: {md: '30%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}}}
        >
          続けて登録する
        </Button>
      </Box>
    </Box>
  );
}
