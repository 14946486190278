import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  breakpoints: {
    values:{
      xs: 0,
      sm: 600,
      md: 769,
      lg: 1025,
      xl: 1536,
    }
  },
  palette: {
    primary: {
      main: '#DF7A99',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#51A7F2',
      contrastText: '#FFFFFF'
    },
    warning: {
      main: '#D2D2D2',
      contrastText: '#000000'
    },
    info: {
      main: '#F1DD98',
      contrastText: '#000000'
    },
    success:{
      main: '#FFFFFF',
      contrastText: '#000000'
    },
    background: {
      default: '#F3F3F3',
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: ['Noto Sans JP', 'sans-serif'].join(',')
  },
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          height: '60px',
          '@media (max-width: 768px)' : {
            height: '60px'
          }
        }
      }
    },
    MuiListItemText: {
      styleOverrides:{
        primary:{
          fontSize: 13,
          fontWeight: 'bold'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          color: '#000000',
          fontSize: 14
        }
      }
    },
    MuiSelect: {
      styleOverrides:{
        root: {
          textAlign: 'left'
        }
      }
    },
    MuiToolbar: {
      styleOverrides:{
        root: {
          '@media (min-width: 600px)' : {
            minHeight: '48px'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides:{
        root: {
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#000",
            },
          },
          '@media (max-width: 768px)' : {
            padding: 0
          }
        },
        input: {
          '@media (max-width: 768px)' : {
            padding: '8px 14px'
          }
        }
      }
    },
    MuiAutocomplete:{
      styleOverrides: {
        inputRoot:{
          '@media (max-width: 768px)' : {
            padding: '1.5px 6px 1.5px 6px'
          }
        },
        popupIndicator:{
          '@media (max-width: 768px)' : {
            paddingBottom: '20px',
            "&:hover":{
              paddingBottom: '20px'
            }
          }
        },
        popupIndicatorOpen:{
          '@media (max-width: 768px)' : {
            paddingTop: '20px',
            "&:hover":{
              paddingTop: '20px'
            }
          }
        }
      }
    },
    MuiButtonBase:{
      styleOverrides: {
        root:{
          '@media (max-width: 768px)' : {
            height: '40px'
          }
        }
      }
    },
    MuiIconButton:{
      styleOverrides: {
        root:{
          '@media (max-width: 768px)' : {
            height: '50px'
          }
        }
      }
    },
    MuiSvgIcon:{
      styleOverrides:{
        fontSizeSmall: {
          '@media (max-width: 768px)' : {
            marginBottom: '20px'
          }
        }
      }
    }
  }
});

export default theme;