import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { passwordUpdate } from "../../lib/api/auth";
import { useForm, Controller } from "react-hook-form";
import { Button, TextField, Hidden, Container, Grid, InputLabel, InputAdornment, IconButton } from '@mui/material';
import Loading from "../../components/Loading";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const validationRules = {
  password:{
    required: 'パスワードを入力してください',
    validate: (value, formValues) =>{
      const passwordPattern = /^([a-zA-Z0-9]{8,})$/
      const passwordPatternStr = /[a-zA-Z]/
      const passwordPatternNum = /[0-9]/
      if(!passwordPattern.test(value)){
        return 'パスワードは8文字以上の半角英数で入力してください'
      }
      if(!passwordPatternStr.test(value)){
        return 'パスワードには、半角英文字を含む必要があります'
      }
      if(!passwordPatternNum.test(value)){
        return 'パスワードには、半角数字を含む必要があります'
      }
      if(value != formValues.passwordConfirmation){
        return 'パスワード(確認用)と一致しません';
      }
    }
  },
  passwordConfirmation:{
    required: 'パスワード(確認用)を入力してください'
  }
}

export const PasswordEdit = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const { control, handleSubmit, setValue, getValues} = useForm({defaultValues: { password: '', passwordConfirmation: '', resetPasswordToken: ''}});
  const navigate = useNavigate();
  const token = query.get('reset_password_token');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  useEffect(() =>{
    if(token) setValue('resetPasswordToken', token);
  }, [])
  const handleSignUpSubmit = async (e) => {
    if(isLoading) return;
    setIsLoading(true);
    try {
      const res = await passwordUpdate(e);
      setIsLoading(false);
      if(res.status === 200){
        navigate("/login/password/resetting/completed");
      }
    } catch (e) {
      if(e.response.status == 422){
        // バリデーションエラー
        const response = e.response;
        setIsLoading(false);
        console.log(response);
      }
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickShowPasswordConfirm = () => setShowPasswordConfirm((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Container
      sx={{
        width: {md: '80%', xs: '95%'},
        pt: 5
      }}
      >
      <form id='sign_up_form' onSubmit={handleSubmit(handleSignUpSubmit)}>
        <Controller
          control={control}
          name='resetPasswordToken'
          render={({field}) =>(
            <Hidden />
          )}
          />
        <Controller
          control={control}
          name='password'
          rules={validationRules['password']}
          render={({ field, fieldState }) => (
            <Grid container>
              <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
              <InputLabel required sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                パスワード
              </InputLabel>
              </Grid>
              <Grid item md={9} sm={12} xs={12}>
            <TextField
              {...field}
              fullWidth
              sx={{mt: {xs: 0, md: 2}}}
              type={showPassword ? 'text' : 'password'}
              margin="normal"
              color="secondary"
              placeholder="入力してください"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              InputProps={{endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {!showPassword ? <VisibilityOff sx={{mr: {xs: 1, md: 0}}}/> : <Visibility sx={{mr: {xs: 1, md: 0}}}/>}
                  </IconButton>
                </InputAdornment>
              )}}
            />
            </Grid>
            </Grid>
          )}
        />
        <Controller
          control={control}
          name='passwordConfirmation'
          rules={validationRules['passwordConfirmation']}
          render={({ field, fieldState }) => (
            <Grid container>
              <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                <InputLabel required sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                  パスワードの確認
                </InputLabel>
              </Grid>
              <Grid item md={9} sm={12} xs={12}>
                <TextField
                  {...field}
                  fullWidth
                  sx={{mt: {xs: 0, md: 2}}}
                  type={showPasswordConfirm ? 'text' : 'password'}
                  margin="normal"
                  color="secondary"
                  placeholder="入力してください"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  InputProps={{endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPasswordConfirm}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {!showPasswordConfirm ? <VisibilityOff sx={{mr: {xs: 1, md: 0}}}/> : <Visibility sx={{mr: {xs: 1, md: 0}}}/>}
                      </IconButton>
                    </InputAdornment>
                  )}}
                />
              </Grid>
            </Grid>
          )}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{borderRadius: 5, width: {md: '30%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}}}
        >
          送信
        </Button>
      </form>
      <Link to="/login">ログイン</Link>
      {isLoading ? <Loading inverted={isLoading}/> : <></>}
    </Container>
  );
};