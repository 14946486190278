import React, {useState, useEffect} from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { deleteProfile } from "../../lib/api/profile"
import { Button, Box, Typography} from '@mui/material';
import { DeleteInput, deleteDefaultValues } from '../../common/profile';
import Loading from "../../components/Loading";
import { useAuthContext } from '../../providers/AuthUser';
import Cookies from 'js-cookie';
import { ErrorModal } from '../../components/ErrorModal';

export const DeleteConfirm = () =>{
  const location = useLocation();
  const [userInfo] = useState<DeleteInput>(location.state);
  const authUser = useAuthContext();
  const { control, handleSubmit, setValue} = useForm<DeleteInput>({defaultValues: deleteDefaultValues});
  const navigate = useNavigate();
  const {id} = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();

  const handleOpenErrorModal = () =>{
    setErrorModalOpen(!errorModalOpen);
  }

  useEffect(() => {
    if(id != null){
      setValue('reasonforwithdrawal', userInfo.reasonforwithdrawal);
      setValue('reasonforwithdrawalOther', userInfo.reasonforwithdrawalOther);
      setValue('hasoptedoutofemail', userInfo.hasoptedoutofemail);
      setValue('id', id);
    }else{
      // 別画面に遷移させる。
      // ページが存在しません。
      // 再度やり直してください。など
    }
  }, []);

  const onClickCancel = () =>{
    if(id == null) return;
    navigate("/cancellation_of_membership/" + id);
  }

  const onSubmit: SubmitHandler<DeleteInput> = async (evt) =>{
    if(id == null) return;
    if(isLoading) return;
    setIsLoading(true);
    try{
      const res = await deleteProfile(evt);
      setIsLoading(false);
      if(res.status===200){
        Cookies.remove('currentUserId');
        Cookies.remove('isSignedIn');
        Cookies.remove('loginUserIds');
        Cookies.remove('isSMSVerified');
        Cookies.remove('_access_token');
        Cookies.remove('_client');
        Cookies.remove('_uid');
        Cookies.remove('_email');
        authUser.signout(() =>{
          navigate("/cancellation_of_membership/completed/");
        });
      }
    }catch(e){
      console.log(e);
      setIsLoading(false);
      setErrorMessage(<Typography>{e.response.data.errors[0]}</Typography>);
      handleOpenErrorModal();
    }
  }

  return (
    <>
      <form id="user-confirm-form" onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            width: {md: '60%'},
            height: {md: '350px'},
            margin: '0 auto',
            borderRadius: '5px',
            minWidth: {md: '570px'},
            mt: {md: 10, xs: 2},
            textAlign: 'left',
            pr: 2,
            pl: 2,
            pt: 1,
            pb: 1
          }}
        >
          <Typography sx={{fontWeight: 'bold', fontSize: 17, pt: 5, textAlign: 'center', mb: 2}}>
            退会は取り消しできません。本当に退会しますか？
          </Typography>
          <Typography>・退会後はサービスをご利用できません。</Typography>
          <Typography>・退会前の情報は復元できない可能性があります。</Typography>
          <Typography>・原則、同一のメールアドレスでの再登録はできません。</Typography>
          <Box sx={{mt: 3, textAlign: 'center'}}>
            <Button
              variant='contained'
              color='warning'
              onClick={() => onClickCancel()}
              sx={{borderRadius: 5, width: {md: '25%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}, mr: 1}}
              >
              キャンセル
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{borderRadius: 5, width: {md: '25%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}}}
            >
              退会する
            </Button>
          </Box>
        </Box>
      </form>
      {isLoading ? <Loading inverted={isLoading}/> : <></>}
      <ErrorModal message={errorMessage} modalOpen={errorModalOpen}></ErrorModal>
    </>
  )
}