import React, { useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Typography} from '@mui/material';

export const PasswordEditSuccess = () =>{
  const navigate = useNavigate();
  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const onClickCancel = () =>{
    navigate('/login');
  }

  return (
    <Box
      sx={{
        width: {md: '60%'},
        height: {md: '200px'},
        margin: '0 auto',
        minWidth: {md: '570px'},
        mt: {md: 10, xs: 2},
        pr: 2,
        pl: 2,
        pt: 1,
        pb: 1
      }}
    >
      <Typography>パスワードの再設定が完了しました。</Typography>
      <Typography>新しいパスワードでログインしてください。</Typography>
      <Box sx={{mt: 3}}>
        <Button
          variant='contained'
          onClick={() => onClickCancel()}
          sx={{borderRadius: 5, minWidth: '210px', width: {md: '30%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}}}
        >
          戻る
        </Button>
      </Box>
    </Box>
  )
}