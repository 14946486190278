import React, { useState } from 'react';
import { Button, Typography, Box, Grid, InputLabel, FormControl, FormHelperText } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Controller, useForm } from 'react-hook-form';
import { DragDropFileUpload } from '../components/DragDropFileUpload';
import { FileInput, defaultFileInput } from '../common/profile';
import { updateProfile } from '../lib/api/profile';
import Loading from '../components/Loading';

export const EkycSuccess = () =>{
  const { control, handleSubmit, setValue, getValues} = useForm<FileInput>({defaultValues: defaultFileInput});
  const navigate = useNavigate();
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const initial = query.get('initial');
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (evt) =>{
    try{
      setIsLoading(true);
      evt.code = "sadfajskljdaskasdkjsfakjsfadkj";
      evt.id = Cookies.get('currentUserId');
      const res = await updateProfile(evt);
      if(res.status === 200){
        if(initial){
          setIsLoading(false);
          navigate('/apply/factoring_new');
        }else{
          setIsLoading(false);
          navigate("/profile/top");
        }
      }
    }catch(e){
      setIsLoading(false);
      console.log(e);
    }
  }

  return (
    <Box
      sx={{
        width: {md: '60%'},
        margin: '0 auto',
        borderRadius: '5px',
        minWidth: {md: '470px'},
        mt: {md: 10, xs: 2},
        pr: 2,
        pl: 2,
        pt: 1,
        pb: 1
      }}
    >
      <Typography>
        「顔写真と保険証が一緒に写ったもの」をアップロードしてください。
      </Typography>
      <Typography>
        ※ユーザー情報の登録時にアップロードした書類をご提出ください。
      </Typography>
      <form id="user-edit-form" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name='files'
        rules={{
          required: '顔写真と保険証が一緒に写ったものを添付してください。',
          validate: (value, formValues) =>{
          if(value && value.length == 0) return '顔写真と保険証が一緒に写ったものを添付してください。'
          }
        }}
        key='files'
        render={({ field: {onChange, value }, fieldState}) =>(
          <Grid container>
            <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
              <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                顔写真と保険証が一緒に映った画像
              </InputLabel>
              <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces', color: '#FF0000', fontSize: 10}}>
                ※保険証を提出したお客様のみご提出ください
              </InputLabel>
            </Grid>
            <Grid item md={9} sm={12} xs={12} sx={{pr: 2}}>
              <FormControl margin='normal' fullWidth error={fieldState.invalid} sx={{mt: {xs: 0, md: 2}}}>
                <DragDropFileUpload onFileUpload={onChange} input_id={1} inputValue={value}/>
                <FormHelperText>{fieldState.error?.message}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        )}
      />
      <Box sx={{mt: 3}}>
        <Button
          variant='contained'
          color='primary'
          type='submit'
          sx={{borderRadius: 5, width: {md: '30%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}, mr: 1}}
        >
          アップロードする
        </Button>
      </Box>
      </form>
      {isLoading ? <Loading inverted={isLoading}/> : <></>}
    </Box>
  );
};