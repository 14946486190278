import {  Box, Grid, Radio, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

function ImageButton({onClick, buttonTitle='', imgUrl='', buttonText='', disabled=false, disableColor="rgb(180, 180, 180)", disableBorder='1px solid #000', arrowEnable=true, isRadio=false}) {
  const navigate = useNavigate();
  
  const onClickLink = (event) =>{
    onClick(event);
  }

  return (
    <>
    {disabled ?
      <Box
        sx={[
          {
            width: {xs: '100%', md: "33%"},
            minHeight: {md: '220px', xs: '120px'},
            maxHeight: {md: '220px', xs: '120px'},
            position: 'relative',
            backgroundColor: disableColor,
            color: '#808080'
          }
        ]}
      >
        <Box sx={{display: {xs: 'none', md: 'block'}}}>
          <Grid>
            <Box component="img" src={imgUrl} sx={{position: 'absolute', left: {xs: 'calc((100% - 125px)/2)', md: 'calc((100% - 150px)/2)'}, top: {md: imgUrl == '/icons/icon_on_profile.svg' ? 15 : 15, xs: 0}, width: {xs: "125px", md: "150px"}}}/>
          </Grid>
          {arrowEnable ?
          <Grid sx={{position: 'absolute', bottom: {md: 10, xs: 5}, width: '100%'}}>
            <Typography sx={{fontWeight: 'bold', fontSize: '13px'}}>{buttonTitle}</Typography>
            <Typography sx={{fontWeight: 'bold', fontSize: '13px', display:{md: 'block', xs: 'none'}}}>{buttonText}</Typography>
          </Grid>
          :
          <></>
          }
          {isRadio ?
            <Grid sx={{position: 'absolute', bottom: {md: 10, xs: 5}, width: '100%', fontWeight: 'bold', fontSize: '13px'}}>
              <Radio checked={true} color="secondary" />{buttonTitle}
            </Grid>
            :
            <></>
          }
        </Box>
        <Box sx={{display: {xs: 'flex', md: 'none'}}}>
          <Box component="img" src={imgUrl} sx={{width: "120px", pl: 2}}/>
          {arrowEnable ?
          <Box sx={{alignContent: 'center', alignItems: 'center', pl: 2, textAlign: 'left'}}>
            {buttonTitle == '過去申請をコピーして審査の申し込み' ?
              <Typography sx={{fontWeight: 'bold', fontSize: '13px'}}>過去の申請をコピーして<br/>審査を申し込む場合はこちら</Typography>
              :
              <Typography sx={{fontSize: '11px', display:{md: 'block', xs: 'block'}}}>{buttonText}</Typography>
            }
            {
              buttonTitle == '過去のファクタリング申請をコピー' ?
              <Typography sx={{fontWeight: 'bold', fontSize: '15px'}}>過去のファクタリング<br/>申請をコピー</Typography>
              :
              <Typography sx={{fontWeight: 'bold', fontSize: '15px'}}>{buttonTitle}</Typography>
            }
          </Box>
          :
          <></>
          }
          {isRadio ?
            <Box sx={{fontSize: '11px', alignContent: 'center', alignItems: 'center', lineHeight: '0'}}>
              <Radio checked={true} color="secondary" />{buttonTitle}
            </Box>
            :
            <></>
          }
        </Box>
      </Box>
      :
      <Box
        onClick={onClickLink}
        sx={[
          {
            width: {xs: '100%', md: "33%"},
            minHeight: {md: '220px', xs: '120px'},
            maxHeight: {md: '230px', xs: '120px'},
            cursor: 'pointer',
            position: 'relative',
          },
          {'&:hover': {
            backgroundColor: '#F0F0F0'
          }}
        ]}
      >
        <Box sx={{display: {xs: 'none', md: 'block'}}}>
          <Grid>
            <Box component="img" src={imgUrl} sx={{position: 'absolute', left: {xs: 'calc((100% - 125px)/2)', md: 'calc((100% - 150px)/2)'}, top: {md: imgUrl == '/icons/icon_on_profile.svg' ? 15 : 15, xs: 0}, width: {xs: "125px", md: "150px"}}}/>
          </Grid>
          {arrowEnable ?
          <Grid sx={{position: 'absolute', bottom: {md: 10, xs: 5}, width: '100%'}}>
            <Typography sx={{fontWeight: 'bold', fontSize: '13px'}}>{buttonTitle}</Typography>
            {buttonTitle == '過去申請をコピーして審査の申し込み' ?
              <Typography sx={{fontWeight: 'bold', fontSize: '13px'}}>過去の申請をコピーして<br/>審査を申し込む場合はこちら</Typography>
              :
              <Typography sx={{fontWeight: 'bold', fontSize: '13px', display:{md: 'block', xs: 'none'}}}>{buttonText}</Typography>
            }
          </Grid>
          :
          <></>
          }
          {isRadio ?
            <Grid sx={{position: 'absolute', bottom: {md: 10, xs: 5}, width: '100%', fontWeight: 'bold', fontSize: '13px'}}>
              <Radio checked={false} color="secondary"/>{buttonTitle}
            </Grid>
            :
            <></>
          }
        </Box>
        <Box sx={{display: {xs: 'flex', md: 'none'}}}>
          <Box component="img" src={imgUrl} sx={{width: "120px", pl: 2}}/>
          {arrowEnable ?
          <Box sx={{alignContent: 'center', alignItems: 'center', pl: 2, textAlign: 'left'}}>
            {buttonTitle == '過去申請をコピーして審査の申し込み' ?
              <Typography sx={{fontWeight: 'bold', fontSize: '13px'}}>過去の申請をコピーして<br/>審査を申し込む場合はこちら</Typography>
              :
              <Typography sx={{fontSize: '11px', display:{md: 'block', xs: 'block'}}}>{buttonText}</Typography>
            }
            {
              buttonTitle == '過去のファクタリング申請をコピー' ?
              <Typography sx={{fontWeight: 'bold', fontSize: '15px'}}>過去のファクタリング<br/>申請をコピー</Typography>
              :
              <Typography sx={{fontWeight: 'bold', fontSize: '15px'}}>{buttonTitle}</Typography>
            }
          </Box>
          :
          <></>
          }
          {isRadio ?
            <Box sx={{fontSize: '11px', alignContent: 'center', alignItems: 'center', lineHeight: '0'}}>
              <Radio checked={false} color="secondary"/>{buttonTitle}
            </Box>
            :
            <></>
          }
        </Box>
      </Box>
    }
    </>
  )
}

export default ImageButton;