import React, {useState} from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Button, Typography} from '@mui/material';

export const FileuploadSuccess = () =>{
  const location = useLocation();
  const navigate = useNavigate();
  const [id, setId] = useState(location.state);

  const onClickCancel = () =>{
    navigate('/home');
  }

  return (
    <Box
      sx={{
        width: {md: '60%'},
        height: {md: '200px'},
        margin: '0 auto',
        minWidth: {md: '570px'},
        mt: {md: 10, xs: 2},
        pr: 2,
        pl: 2,
        pt: 1,
        pb: 1
      }}
    >
      <Typography>追加書類のアップロードを完了しました。</Typography>
      <Typography>内容を確認し、担当者からご連絡いたします。</Typography>
      <Box sx={{mt: 3}}>
        <Button
          variant='contained'
          onClick={() => onClickCancel()}
          sx={{borderRadius: 5, minWidth: '100px', width: {md: '30%', xs: '100%'}, fontWeight: 'bold', mb: {xs: 1, md: 2}, mr: 1}}
        >
          トップページ
        </Button>
      </Box>
    </Box>
  )
}