import React, { useEffect, useState } from 'react';
import {Typography, Link, Box, Divider } from '@mui/material';
import { Notification } from '../../components/Notification';
import { useNavigate } from "react-router-dom";
import { Todo } from '../../components/Todo';
import Cookies from 'js-cookie';
import { getCollectionNotifications, getCollections, getNotifications, getProfile, getTodos } from '../../lib/api/admin';

interface Notification{
  bodyC: string;
  createdbyid: string;
  createddate: string;
  hcErr: string;
  hcLastop: string;
  id: number;
  isdelted: boolean;
  name: string;
  notificationkeyC: string;
  notificationtypeC: string;
  portalNotificationenddatetimeC: string;
  portalNotificationstartdatetimeC: string;
  portalNotificationstatusC: string;
  sfid: string;
  subjectC: string;
  systemmodstamp: string;
}

interface Todo{
  id: string;
  sfid: string;
  name: string;
  opportunityId: string;
  opportunitySfid: string;
}

export const AdminTop = () =>{
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [todos, setTodos] = useState<Todo[]>([]);
  const [excesses, setExcesses] = useState<Todo[]>([]);
  const [collections7, setCollections7] = useState([]);
  const [collections3, setCollections3] = useState([]);
  const navigate = useNavigate();
  const [isLoad, setIsLoad] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const [supportingdocuments2, setSupportingdocuments2] = useState(false);

  const handleGetNotifications = async() =>{
    try{
      const res = await getNotifications();
      const res2 = await getProfile();
      if(res.status === 200){
        setNotifications(res.data.records.filter((rec) => rec.notificationtypeC == 'お知らせ'));
      }
      if(res2.status === 200){
        if(res2.data.record.supportingdocuments2 == '未確認') setSupportingdocuments2(true);
      }
    }catch(e){
      console.log(e);
    }
  }

  const handleGetOpportunities = async() =>{
    const res = await getCollections(7);
    const res2 = await getCollections(3);
    if(res.status === 200){
      setCollections7(res.data.records);
      setCollections3(res2.data.records);
    }
  }

  const handleGetTodos = async() =>{
    const res = await getTodos();
    if(res.status === 200){
      setTodos(res.data.records);
    }
  }

  const handleGetExcess = async() =>{
    const res = await getCollectionNotifications();
    if(res.status === 200){
      setExcesses(res.data.records.filter((f) => f.opportunityId != null).map((f) => {
        f.name = '入金予定日：' + f.name;
        return f;
      }));
      setIsLoad(true);
    }
  }

  useEffect(() =>{
    handleGetNotifications();
    handleGetOpportunities();
    handleGetTodos();
    handleGetExcess();
    if(Cookies.get('smsVerifiedAt') && Cookies.get('smsVerifiedAt') != 'undefined'){
      setNewUser(true);
    }
  }, []);

  const onClickDueDate = (type) =>{
    if(type == 0){
      navigate("/admin/due_date_list");
    }else if(type == 3){
      navigate("/admin/due_date_list?type=3");
    }else if(type == 7){
      navigate("/admin/due_date_list?type=7");
    }
  }

  return (
    <Box sx={{pt: 5, backgroundColor: {xs: '#FFFFFF'}, p: {xs: 1, md: 0}, borderRadius: 2}}>
      { isLoad ?
        <>
        { todos.length > 0 || excesses.length > 0 ?
          <Box
            sx={{
              border: '1px solid #D06960',
              backgroundColor: "#FBF1EE",
              borderRadius: '5px',
              width: {md: '90%', xs: '100%'},
              padding: 2,
              margin: '0 auto',
              marginBottom: '30px'
            }}
          >
            <Typography className='betrading_message_title' sx={{
              fontWeight: 'bold'
            }}>
              <Box component='img' src='/icons/icon_important.svg' sx={{
                height: '20px',
                position: 'absolute'
              }}/>
              <Box component='span' sx={{
                ml: '23px'
              }}>
                重要なお知らせ
              </Box>
            </Typography>
            {
              todos.length > 0 ?
              <Todo subject={`【重要】ご提出いただきたい書類(${todos.length}件)`} todos={todos} baseUrl='/admin/add_file' isViewDate={true}/>
              :
              <></>
            }
            {
              excesses.length > 0 ?
              <Todo subject={`【重要】至急ご確認ください(${excesses.length}件)`} todos={excesses} baseUrl='/admin/status/detail' isViewDate={false}/>
              :
              <></>
            }
          </Box>
          :
          <></>
        }
        <Box sx={{
          width: {md: '90%', xs: '100%'},
          padding: 2,
          margin: '0 auto',
          marginBottom: '15px'
        }}>
          <Typography className='betrading_message_title'>
            <Box component='span' sx={{
              backgroundColor: '#F5D3A3',
              width: '15px',
              height: '15px',
              display: 'inline-block',
              border: '1px solid #F5D3A3',
            }}>
            </Box>
            <Box component='span' onClick={(e) =>onClickDueDate(0)} sx={{
              paddingLeft: 1,
              fontWeight: 'bold',
              cursor: 'pointer'
            }}>
              入金予定日リスト
            </Box>
          </Typography>
          {collections7.length > 0 ? 
            <Typography className='betrading_message_content' sx={{
              marginLeft: 3,
              marginTop: 1,
              fontSize: 14.5
            }}>
              <Box component='span'
                sx={{
                  color: '#DF7A99'
                }}
              >
                {`▶︎ `}
              </Box>
              <Box component='span' onClick={(e) => onClickDueDate(7)} sx={{
                textDecoration: 'underline',
                cursor: 'pointer'
              }}>
                入金予定日7日前の売掛金があります({collections7.length}件中 未読{collections7.filter((f) => f.sevendayscheck == 2).length}件)
              </Box>
            </Typography>
          : ''}
          {collections3.length > 0 ? 
            <Typography className='betrading_message_content' sx={{
              marginLeft: 3,
              marginTop: 1,
              fontSize: 14.5
            }}>
              <Box component='span'
                sx={{
                  color: '#DF7A99'
                }}
              >
                {`▶︎ `}
              </Box>
              <Box component='span' onClick={(e) => onClickDueDate(3)} sx={{
                textDecoration: 'underline',
                cursor: 'pointer'
              }}>
                入金予定日3日前の売掛金があります({collections3.length}件中 未読{collections3.filter((f) => f.threedayscheck == 2).length}件)
              </Box>
            </Typography>
          : ''}
        </Box>
        <Divider/>
        <Box sx={{
          width: {md: '90%', xs: '100%'},
          padding: 2,
          margin: '0 auto',
          marginTop: '15px'
        }}>
          <Typography className='betrading_message_title'>
            <Box component='span' sx={{
              backgroundColor: '#F5D3A3',
              width: '15px',
              height: '15px',
              display: 'inline-block',
              border: '1px solid #F5D3A3',
            }}>
            </Box>
            <Box component='span' sx={{
              paddingLeft: 1,
              fontWeight: 'bold'
            }}>
              お知らせ
            </Box>
          </Typography>
          { supportingdocuments2 ?
          <Typography className='betrading_message_content' sx={{
            marginLeft: 3,
            marginTop: 1,
            fontSize: 14.5
          }}>
            <Link
              component='span'
              onClick={(e) => navigate('/personalidentification')}
              sx={[{
                cursor: 'pointer',
                textDecoration: 'none',
              },{
                '&:hover':{
                  textDecoration: 'underline'
                }
              }]}
            >
              <Box component='span'
                sx={{
                  color: '#DF7A99'
                }}
              >
                {`▶︎ `}
              </Box>
              <Box component='span'>
                未提出の画像があります。
              </Box>
            </Link>
          </Typography>
          :
          <></>
          }
          { newUser ?
          <Typography className='betrading_message_content' sx={{
            marginLeft: 3,
            marginTop: 1,
            fontSize: 14.5
          }}>
            <Box component='span'
              sx={{
                color: '#DF7A99'
              }}
            >
              {`▶︎ `}
            </Box>
            <Box component='span'>
              新規会員登録ありがとうございます
            </Box>
          </Typography>
          : ''}
          {
            notifications.map((nfc, i) =>{
              return (
                <Notification key={`ntif_${i}`} subject={nfc.subjectC} body={nfc.bodyC}/>
              )
            })
          }
        </Box>
        </>
        :
        <></>
      }
    </Box>
  );
};