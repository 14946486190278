import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import { Modal, Box, Hidden, TextField, Button, InputLabel, Select, MenuItem, FormControl, Typography, Grid, Autocomplete, FormGroup, FormHelperText} from '@mui/material';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Input, defaultValues, AccountsReceivableFormInfo, validationRules } from '../common/accounts_receivable';
import { createAccountsReceivable, getAccountsReceivables} from "../lib/api/accounts_receivable"
import { getAddress } from "../lib/api/postalCode"
import { Corporate, TextFieldWButton, TextFieldWText } from "../common/profile";
import { getCorporates } from "../lib/api/corporate";
import { ErrorModal } from "./ErrorModal";
import { Element, scroller} from 'react-scroll';
import { sendErrorMail } from "../lib/api/error_mail";

const phraseStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {xs: '100%', md: '70%'},
  height: '70%',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

export const AccountsReceivableModal = (props) =>{
  const [open, setOpen] = useState(true);
  const { control, handleSubmit, setValue, getValues} = useForm<Input>({defaultValues});
  const [kindState, setKind] = useState('法人');
  const [corporates, setCorporates] = useState<Corporate[]>([]);
  const corpRef = useRef<HTMLInputElement>(null);
  const [acValue, setAcValue] = useState('');
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();

  useEffect(()=>{
    setCorporates([{
      name: '',
      corporateNumber: '',
      furigana: '',
      postCode: '',
      prefectureName: '',
      cityName: '',
      streetNumber: ''
    }])
  }, []);

  useLayoutEffect(() =>{
    setOpen(!open);
  }, [props.modalOpen])

  const handleClose = () => {
    setValue('kind', '法人');
    setValue('companyName', '');
    setValue('postalcode', '');
    setValue('state', '');
    setValue('city', '');
    setValue('street', '');
    setValue('houjinNo', '');
    setValue('other', '');
    setValue('hpAddress', '');
    setValue('phone', '');
    setValue('manager', '');
    setValue('fixDate', '');
    setValue('paymentDate', '');
    setOpen(false);
  };

  const onSubmit: SubmitHandler<Input> = async (evt) =>{
    const resReceivable = await getAccountsReceivables();
    if(resReceivable.status === 200){
      const accReceivables = resReceivable.data.records.map(rec =>{
        return {
          kind: rec.type ? rec.type : '',
          companyName: rec.companyname ? rec.companyname : '',
          state: rec.state ? rec.state : '',
          city: rec.city ? rec.city : '',
          street: rec.street ? rec.street: '',
          other: rec.other ? rec.other : '',
          postalcode: rec.postalcode ? rec.postalcode : '',
          hpAddress: rec.hp ? rec.hp : '',
          phone: rec.telno ? rec.telno : '',
          fixDate: rec.closingdate ? rec.closingdate : '',
          paymentDate: rec.paymentdate ? rec.paymentdate : '',
          manager: rec.personincharge ? rec.personincharge : '',
          houjinNo: rec.corporationnumber ? rec.corporationnumber : ''
        }
      });
      const exist = kindState == '法人' ? accReceivables.find((ar) => {
        return (
          ar.companyName.replace(/\s+/g,'') == evt.companyName.replace(/\s+/g,'') &&
          ar.paymentDate == evt.paymentDate &&
          ar.fixDate == evt.fixDate &&
          ar.kind == evt.kind &&
          ar.houjinNo == evt.houjinNo
        );
      }) : accReceivables.find((ar) =>{
        return (
          ar.companyName.replace(/\s+/g,'') == evt.companyName.replace(/\s+/g,'') &&
          ar.phone == evt.phone &&
          ar.kind == evt.kind &&
          ar.paymentDate == evt.paymentDate &&
          ar.fixDate == evt.fixDate
        );
      })
      if(exist){
        setErrorMessage(<Typography>登録済みの売掛先です</Typography>);
        handleOpenModal();
        return;
      }
    }
    const res = await createAccountsReceivable(evt);
    const res2 = await getAccountsReceivables();
    if(res2.status === 200){
      props.compEvent(res2.data.records);
    }
    handleClose();
  }

  const onError = (evt) =>{
    const fields = [
      'kind', 'companyName', 'postalcode', 'state', 'city', 'street',
      'other', 'houjinNo', 'hpAddress', 'phone', 'manager', 'fixDate', 'paymentDate'
    ]
    const keys = Object.keys(evt);
    let targetKey = '';
    for(let i = 0; i < fields.length; i++){
      if(keys.find(k => k == fields[i])){
        targetKey = fields[i];
        break;
      }
    }
    const form = document.getElementById('accounts-receivable-new-form');
    const pform = form?.parentElement;
    const pformInfo = pform?.getBoundingClientRect();
    const keyInput = document.getElementsByName(targetKey);
    const inputInfo = keyInput[0].getBoundingClientRect();
    if(!pform || !pformInfo) return;
    pform.scrollTo({
      top: pform.scrollTop + inputInfo.top - pformInfo.top - 50,
      behavior: 'smooth'
    })
  };

  const handleAddressSearch = async (fid, value) =>{
    const res = await getAddress(value);
    const stateStr = 'state';
    const cityStr = 'city';
    const streetStr = 'street';
    if(res.status == 200 && res.data.results){
      // 1件取得できた時だけ値を更新する
      const address = res.data.results[0];
      setValue(stateStr, address.address1);
      setValue(cityStr, address.address2);
      setValue(streetStr, address.address3);
    }else{
      if(res.status == 500){
        await sendErrorMail(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${value}`, JSON.stringify(res.data), 'ZIP Cloud API');
      }
    }
  }

  const handleOpenModal = () =>{
    setErrorModalOpen(!errorModalOpen);
  }

  const searchCompany = async() =>{
    const values = getValues();
    try{
      const ret = await getCorporates({name: values.companyName});
      const count = parseInt(ret.data.records.corporations.count);
      if(count > 1){
        setCorporates(ret.data.records.corporations.corporation);
      }else if(count == 1){
        setCorporates([ret.data.records.corporations.corporation]);
      }else{
        setCorporates([{
          name: '',
          corporateNumber: '',
          furigana: '',
          postCode: '',
          prefectureName: '',
          cityName: '',
          streetNumber: ''
        }])
        setErrorMessage(<Typography>検索候補がありません、入力内容を再度ご確認ください</Typography>);
        handleOpenModal();
      }
      setAcValue(values.companyName);
    }catch(e){
      setErrorMessage(<Typography>{e.response.data.message}</Typography>)
      handleOpenModal();
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={phraseStyle}>
        <form id="accounts-receivable-new-form" onSubmit={handleSubmit(onSubmit, onError)}>
          {AccountsReceivableFormInfo.map((group, index) =>(
            <Box
              key={`stm_${index}`}
              sx={{backgroundColor: '#F4F6F9', borderRadius: '10px', pb: 2, pr: 5, pl: 5, mt: 2}}
            >
              <Box sx={{display: 'flex'}}>
                <Box component='span' sx={{
                  backgroundColor: '#51A7F2',
                  width: '3px',
                  height: '25px',
                  display: 'inline-block',
                  border: '1px solid #51A7F2',
                  transform: 'rotate(45deg)',
                  borderRadius: '5px',
                  mb: '-6px',
                  mr: '5px'
                }}/>
                <Box component='span' sx={{
                  backgroundColor: '#DF7A99',
                  width: '3px',
                  height: '25px',
                  display: 'inline-block',
                  border: '1px solid #DF7A99',
                  transform: 'rotate(45deg)',
                  borderRadius: '5px',
                  mb: '-6px'
                }}/>
                <Typography sx={{fontWeight: 'bold', fontSize: 20, ml: 2}}>{group.title}</Typography>
              </Box>
              <Typography sx={{fontWeight: 'bold', fontSize: 16, textAlign: 'center'}}>{group.message}</Typography>
              <Typography sx={{fontWeight: 'bold', fontSize: 16, color: "#FF0000", textAlign: 'center'}}>{group.warning}</Typography>
              { 
                group.forms.map((pform, idx) =>(
                  pform.fields.length > 1 && pform.id == 'horizontal' ?
                    <Grid container key={`pf_g_${idx}`}>
                      <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                        <InputLabel required={pform.required} sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                          {pform.label}
                        </InputLabel>
                      </Grid>
                      {pform.fields.map((f) =>(
                        <Controller
                          key={f.id}
                          control={control}
                          name={f.id}
                          rules={validationRules[f.id]}
                          render={({ field, fieldState }) => (
                            <Grid item md={4.5} sm={6} xs={6}>
                              <TextField
                                {...field}
                                fullWidth
                                disabled
                                sx={{mt: {xs: 0, md: 2}}}
                                margin="normal"
                                color="secondary"
                                placeholder={f.placeholder}
                                error={fieldState.invalid}
                                helperText={fieldState.error?.message}
                              />
                            </Grid>
                          )}
                        />
                      ))}
                    </Grid>
                  : pform.fields.length > 1 && pform.id == 'vertical'?
                    <Box key={`pf_b_${idx}`}>
                      {pform.fields.map((f, i) =>(
                        <Grid container key={`pf_b_${idx}_${i}`}>
                          <Grid item md={3} sm={12} xs={12}sx={{m: 'auto'}}>
                            <InputLabel required={i==0 ? pform.required : false} sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                              {i == 0 ? pform.label : ''}
                            </InputLabel>
                          </Grid>
                          <Controller
                            key={f.id}
                            control={control}
                            name={f.id}
                            rules={validationRules[f.id]}
                            render={({ field, fieldState }) => (
                              <Grid item md={9} sm={12} xs={12}>
                                {f.id == 'postalcode' ?
                                <FormGroup row>
                                  <TextFieldWText
                                    {...field}
                                    fullWidth
                                    sx={{mt: {xs: 0, md: 1, sm: 1}}}
                                    color="secondary"
                                    placeholder={f.placeholder}
                                    error={fieldState.invalid}
                                    helperText={fieldState.error?.message}
                                  />
                                  <TextFieldWButton sx={{mt: {xs: 0, md: 1, sm: 1}}} variant="contained" onClick={(e) =>{handleAddressSearch(f.id, field.value)}}>住所検索</TextFieldWButton>
                                </FormGroup>
                                :
                                <TextField
                                  {...field}
                                  fullWidth
                                  sx={{mt: {xs: 0, md: 1, sm: 1}}}
                                  color="secondary"
                                  placeholder={f.placeholder}
                                  error={fieldState.invalid}
                                  helperText={fieldState.error?.message}
                                />
                                }
                              </Grid>
                            )}
                          />
                        </Grid>
                      ))}
                    </Box>
                  : pform.fields.length == 1 && (pform.fields[0].type == 'string'?
                    <Controller
                      key={pform.fields[0].id}
                      control={control}
                      name={pform.fields[0].id}
                      rules={validationRules[pform.fields[0].id]}
                      render={({ field, fieldState }) => (
                        pform.fields[0].id == 'companyName' && (!kindState || kindState == '法人') ?
                        <Autocomplete
                          freeSolo
                          options={corporates}
                          getOptionLabel={(option: any) => option.name || ''}
                          renderOption={(props, option) => (
                            <Box key={option?.corporateNumber} >
                              <Typography {...props}>
                                {option?.name} ({option?.prefectureName}{option?.cityName}{option?.streetNumber})
                              </Typography>
                            </Box>
                          )}
                          value={corporates.length == 1 ? corporates[0] : ''}
                          onChange={(_event, value: any | null) => {
                            if(value){
                              setValue('companyName', value?.name);
                              setValue('postalcode', value?.postCode);
                              setValue('state', value?.prefectureName);
                              setValue('city', value?.cityName);
                              setValue('street', value?.streetNumber);
                              setValue('houjinNo', value?.corporateNumber);
                            }
                          }}
                          onInputChange={(_event, value, reason) =>{
                            if(reason == 'clear' || reason == 'input'){
                              setValue('companyName', '');
                              setValue('postalcode', '');
                              setValue('state', '');
                              setValue('city', '');
                              setValue('street', '');
                              setValue('houjinNo', '');
                            }
                            if(reason == 'reset' && value && corporates.length == 1){
                              setValue('companyName', corporates[0].name);
                              setValue('postalcode', corporates[0].postCode);
                              setValue('state', corporates[0].prefectureName);
                              setValue('city', corporates[0].cityName);
                              setValue('street', corporates[0].streetNumber);
                              setValue('houjinNo', corporates[0].corporateNumber);
                            }
                          }}
                          renderInput={(params) => (
                            <Grid container>
                              <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                                <InputLabel required sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                                  {pform.label}
                                </InputLabel>
                                <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces', color: '#FF0000', fontSize: 10}}>
                                  ※株式会社/有限会社等は入力しない
                                </InputLabel>
                                <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces', color: '#FF0000', fontSize: 10}}>
                                  ※エンターキーでは検索できません
                                </InputLabel>
                              </Grid>
                              <Grid item md={9} sm={12} xs={12}>
                                <FormGroup row >
                                  <TextFieldWText
                                    {...field}
                                    {...params}
                                    ref={corpRef}
                                    disabled={pform.fields[0].disable}
                                    placeholder={pform.fields[0].placeholder}
                                    error={fieldState.invalid}
                                    helperText={fieldState.error?.message}
                                    margin="normal"
                                  />
                                  <TextFieldWButton variant="contained" onClick={searchCompany}>検索</TextFieldWButton>
                                </FormGroup>
                              </Grid>
                            </Grid>
                          )}
                        />
                        :
                        <Grid container sx={{display: (pform.fields[0].id == 'houjinNo' && kindState == '個人事業主') ? 'none' : 'flex'}}>
                          <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                            <InputLabel required={pform.required} sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                              {pform.label}
                            </InputLabel>
                          </Grid>
                          <Grid item md={9} sm={12} xs={12}>
                            <TextField
                              {...field}
                              fullWidth
                              sx={{mt: {xs: 0, md: 2}}}
                              margin="normal"
                              color="secondary"
                              disabled={pform.fields[0].disable}
                              placeholder={pform.fields[0].placeholder}
                              error={fieldState.invalid}
                              helperText={fieldState.error?.message}
                            />
                          </Grid>
                        </Grid>
                      )}
                    />
                  : pform.fields.length == 1 && (pform.fields[0].type == 'select') ?
                    <Controller
                      key={pform.fields[0].id}
                      control={control}
                      name={pform.fields[0].id}
                      rules={validationRules[pform.fields[0].id]}
                      render={({ field, fieldState }) => (
                        <Grid container>
                          <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                            <InputLabel required={pform.required} sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                              {pform.label}
                            </InputLabel>
                          </Grid>
                          <Grid item md={9} sm={12} xs={12}>
                            <FormControl fullWidth margin='normal' error={fieldState.invalid} sx={{mt: {xs: 0, md: 2}}}>
                              <Select
                                labelId='kind_select'
                                {...field}
                                disabled={pform.fields[0].disable}
                                color="secondary"
                                onChange={(evt) =>{
                                  setKind(evt.target.value);
                                  setValue(pform.fields[0].id, evt.target.value);
                                  setValue('companyName', '');
                                  setValue('postalcode', '');
                                  setValue('state', '');
                                  setValue('city', '');
                                  setValue('street', '');
                                  setValue('houjinNo', '');
                                }}
                              >
                                <MenuItem value='' sx={{color:'gray'}}>選択してください</MenuItem>
                                {pform.fields[0].options.map((opt) =>(
                                  <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
                                ))}
                              </Select>
                              <FormHelperText>{fieldState.error?.message}</FormHelperText>
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}
                    />
                  : <Box key={`pf_n_${idx}`}></Box>
                )))
              }
            </Box>
          ))}
          <Box sx={{textAlign: 'center'}}>
            <Button
              variant='contained'
              color='warning'
              onClick={() => handleClose()}
              sx={{borderRadius: 5, width: '30%', fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}, mr: 1}}
            >
              キャンセル
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{borderRadius: 5, width: '30%', fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}, mr: 1}}
            >
              保存
            </Button>
          </Box>
        </form>
        <ErrorModal message={errorMessage} modalOpen={errorModalOpen}></ErrorModal>
      </Box>
    </Modal>
  )
}